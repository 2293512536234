import { Box, HoverCard } from '@mantine/core';
import WarningIcon from '@/icons/warning.svg';
import { WarningList } from '@/app/assets/components/table/columns/basicInfoColumn/InfosList';

interface AssetWarningsTooltipProps {
  warnings: string[];
}

export function AssetWarningsTooltip({ warnings }: AssetWarningsTooltipProps) {
  if (!warnings.length) {
    return null;
  }

  return (
    <HoverCard shadow='md'>
      <HoverCard.Target>
        <Box>
          <WarningIcon color='#fd7e14' />
        </Box>
      </HoverCard.Target>
      <HoverCard.Dropdown p={0}>
        <WarningList warnings={warnings} />
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
