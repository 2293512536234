import { Group, Text, Tooltip } from '@mantine/core';
import { AssetRow } from '@/app/assets/components/table/hooks/types';
import { MouseEvent, useState } from 'react';
import { FormatsExpander } from '@/app/assets/components/table/atoms/FormatsExpander';
import { useHover } from '@mantine/hooks';
import { FormatBadge } from '../atoms/FormatBadge';

function isArrayOfObjects(formats: any): formats is { filename: string; format: string }[] {
  if (!Array.isArray(formats)) return false;
  return formats.every((item) => typeof item === 'object' && item !== null && 'filename' in item && 'format' in item);
}

function isArrayOfStrings(formats: any): formats is string[] {
  if (!Array.isArray(formats)) return false;
  return formats.every((item) => typeof item === 'string');
}

export function FormatColumn({
  assetId,
  formats,
  onDownload,
}: {
  assetId: number;
  formats: AssetRow['formats'];
  onDownload: (format: string, filename?: string) => void;
}) {
  const id = `asset-${assetId}-formats`;
  const [isExpanded, setIsExpanded] = useState(false);
  const { hovered, ref } = useHover();

  if (!Array.isArray(formats) || !formats.length) {
    return null;
  }
  const shouldTooltip = formats.length > 3;

  let formattedFormats: string[] = [];

  // Check if formats is an array of objects with filename and format properties (rapidmodels)
  if (isArrayOfObjects(formats)) {
    const objectFormats = formats as { filename: string; format: string }[];
    // Map each object to a string representing the format in lowercase with a leading dot
    formattedFormats = objectFormats.map((entry) => `.${entry.format.toLocaleLowerCase()}`);
  }
  // Check if formats is an array of strings (all other cases e.g. rawmodel, pbr stuff)
  else if (isArrayOfStrings(formats)) {
    // Map each string to a lowercase format with a leading dot
    formattedFormats = formats.map((format: string) => `.${format.toLocaleLowerCase()}`);
  }

  const onBadgeClick = (e: MouseEvent<HTMLDivElement>, format: string) => {
    onDownload(format);
  };

  if (shouldTooltip) {
    return (
      <Tooltip ref={ref} label={isExpanded ? '' : formattedFormats.slice(3).join(', ')} opened={!isExpanded && hovered}>
        <Group gap='xs' wrap='wrap' justify='center' id={id} onClick={(e) => e.stopPropagation()}>
          {isArrayOfObjects(formats)
            ? formats.slice(0, 3).map((entry, index) => (
                <FormatBadge key={index} onClick={(e) => onDownload(entry.format, entry.filename)}>
                  <Text>{`.${entry.format.toLocaleLowerCase()}`}</Text>
                </FormatBadge>
              ))
            : formattedFormats.slice(0, 3).map((format, index) => (
                <FormatBadge key={index} onClick={(e) => onBadgeClick(e, format)}>
                  <Text>{format}</Text>
                </FormatBadge>
              ))}
          <FormatsExpander
            hiddenFormats={formattedFormats.slice(3)}
            onDownload={onDownload}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        </Group>
      </Tooltip>
    );
  }

  return (
    <Group gap='xs' wrap='wrap' justify='center' id={id} onClick={(e) => e.stopPropagation()}>
      {isArrayOfObjects(formats)
        ? formats.map((entry, index) => (
            <FormatBadge key={index} onClick={(e) => onDownload(entry.format, entry.filename)}>
              <Text>{`.${entry.format.toLocaleLowerCase()}`}</Text>
            </FormatBadge>
          ))
        : formattedFormats.map((format, index) => (
            <FormatBadge key={index} onClick={(e) => onBadgeClick(e, format)}>
              <Text>{format}</Text>
            </FormatBadge>
          ))}
    </Group>
  );
}
