import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  RpdxVersions,
  getAppVersion,
  getRPDXVersion,
  getRapidPBRVersion,
  getRapidQCVersion,
} from '../actions/versions';

export const versionsQueryKey = ['versions'];

type RapidVersions = { pbrVersion: string; rpdxVersions: RpdxVersions; appVersion: string; qcVersion: string };

export function useVersionsQuery(options?: Omit<UseQueryOptions<RapidVersions>, 'queryKey' | 'queryFn'>) {
  return useQuery<RapidVersions>({
    ...options,
    queryKey: versionsQueryKey,
    queryFn: async () => {
      const rpdxVersions = await getRPDXVersion();
      const pbrVersion = await getRapidPBRVersion();
      const appVersion = await getAppVersion();
      const qcVersion = await getRapidQCVersion();

      return { pbrVersion, rpdxVersions, appVersion, qcVersion };
    },
    throwOnError: false,
  });
}
