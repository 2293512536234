import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getWorkflows } from '../../../actions/workflows';
import { Workflow } from '../../../actions/interfaces';
import { useSearchParams } from 'next/navigation';
import { TAB_QUERY_KEY, SEARCH_QUERY_KEY, WorkflowTab, getActiveWorkflowTab } from '../../../utils/navigation';
import { getSearchParam } from '@/utils/getSearchParam';
import { useShowNewPresets } from '@/app/contexts/ShowNewPresetsContext';
import { useCallback } from 'react';
import { useGrowthbookContext } from '@/app/contexts/GrowthbookContext';
import { WorkflowType } from '@/app/workflows/actions/constants';
import { isRpdx7 } from '@/app/workflows/utils/isRpdx7';

export const WORKFLOWS_QUERY_MAIN_KEY = 'workflows';

export const useWorkflowsQueryKey = (
  tab?: WorkflowTab,
  search?: string | null,
  isRpdxFullRelease?: boolean,
  showNewPresets?: boolean
) => {
  return [WORKFLOWS_QUERY_MAIN_KEY, tab, search, !!isRpdxFullRelease, !!showNewPresets];
};

export const useWorkflowsParams = (tab?: WorkflowTab, searchText?: string) => {
  const searchParams = useSearchParams();

  const activeTab = tab ?? getActiveWorkflowTab(searchParams.get(TAB_QUERY_KEY));
  const search = searchText ?? getSearchParam(searchParams.get(SEARCH_QUERY_KEY));

  return {
    activeTab,
    search,
  };
};

export const useWorkflowsQuery = (
  options?: Omit<UseQueryOptions<Workflow[]>, 'queryKey' | 'queryFn'>,
  tab?: WorkflowTab,
  searchText?: string
) => {
  const { activeTab, search } = useWorkflowsParams(tab, searchText);
  const { showNewPresets } = useShowNewPresets();
  const growthbook = useGrowthbookContext();
  const isRpdxFullRelease = growthbook?.isOn('new-settings-full-release');

  return useQuery({
    ...options,
    staleTime: Infinity,
    queryKey: useWorkflowsQueryKey(activeTab, search, isRpdxFullRelease, showNewPresets),
    queryFn: async () => {
      const workflows = await getWorkflows(activeTab, search);

      if (isRpdxFullRelease) {
        return workflows.filter(
          (workflow) => isRpdx7(workflow.rpdxVersion) || workflow.clientMetadata.type === WorkflowType.CUSTOM
        );
      }

      if (showNewPresets) {
        return workflows.filter((workflow) => isRpdx7(workflow.rpdxVersion));
      }
      return workflows.filter((workflow) => !isRpdx7(workflow.rpdxVersion));
    },
    placeholderData: (prev) => prev,
    structuralSharing: false,
  });
};

export const useSingleWorkflow = (id: number) => {
  const { data: workflows } = useWorkflowsQuery();

  return workflows?.find((workflow) => workflow.id === id);
};

export const useWorkflowsCount = (searchTerm: string) => {
  const { refetch } = useWorkflowsQuery({ enabled: false }, undefined, searchTerm);

  const getCount = useCallback(async () => {
    const { data } = await refetch();
    return data?.length ?? 0;
  }, [refetch]);

  return getCount;
};
