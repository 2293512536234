import { Group, Progress, Text } from '@mantine/core';
import CheckIcon from '@/icons/checkMark.svg';
import { Row } from '@tanstack/table-core';
import { AssetRow } from '@/app/assets/components/table/hooks/types';
import { useMemo } from 'react';
import progressStyles from '../atoms/styles.module.css';

type PipelineInfoColumnProps = {
  name: string;
  outputsCount: number;
  row: Row<AssetRow>;
};

export function PipelineInfoColumn({ name, outputsCount, row }: PipelineInfoColumnProps) {
  const workflowProgress = useMemo(
    () =>
      row.original.outputs.reduce(
        (acc, next) =>
          next.workflowProgress != undefined && next.workflowStatus !== 'failed'
            ? {
                count: acc.count + 1,
                progress: acc.progress + next.workflowProgress,
              }
            : acc,
        { count: 0, progress: 0 }
      ),
    [row.original.outputs]
  );

  const isRecent = useMemo(
    () => isWithinLastMinute(row.original) && workflowProgress.count === 0,
    [row.original, workflowProgress.count]
  );

  const progress = workflowProgress.progress / workflowProgress.count;
  return (
    <Group justify='flex-start' wrap='nowrap'>
      <Text c='dark.9' fw={600}>
        {name}
      </Text>
      <Text c='gray.6' size='sm'>
        {outputsCount} output {outputsCount === 1 ? 'model' : 'models'}
      </Text>
      {isRecent && <CheckIcon style={{ color: 'var(--mantine-color-teal-7)' }} />}
      {workflowProgress.count > 0 && (
        <Group pos='relative'>
          <Progress
            w={500}
            pos='absolute'
            color='teal.7'
            value={progress}
            size='lg'
            transitionDuration={300}
            flex={1}
            className={progress === 0 ? progressStyles.progress : ''}
          />
        </Group>
      )}
    </Group>
  );
}

function isWithinLastMinute(assetRow: AssetRow) {
  const dates: number[] = assetRow.outputs.filter((n) => n).map((o) => new Date(o.createdAt!).getTime());

  const date = Math.max(...dates);
  const now = new Date();
  const difference = now.getTime() - date;

  return difference < 60000;
}
