import { createContext, PropsWithChildren, useContext } from 'react';
import { useDisclosure } from '@mantine/hooks';

type RunWorkflowValue = {
  open: () => void;
  close: () => void;
  opened: boolean;
};

const RunWorkflowContext = createContext<RunWorkflowValue>({
  open: () => {},
  close: () => {},
  opened: false,
});

export const RunWorkflowProvider = ({ children }: PropsWithChildren) => {
  const [opened, { open, close }] = useDisclosure(false);

  return <RunWorkflowContext.Provider value={{ open, close, opened }}>{children}</RunWorkflowContext.Provider>;
};

export const useRunWorkflow = () => {
  return useContext(RunWorkflowContext);
};
