import axios from 'axios';
//import * as Sentry from '@sentry/nextjs';

import { customSignOut } from '@/utils/customSignOut';
import { getImpersonation } from '@/utils/impersonation';

const cloudApiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  withCredentials: true,
  withXSRFToken: true,
});

cloudApiClient.interceptors.request.use((config) => {
  if (config.endpointWithImpersonation) {
    const { isImpersonating, email } = getImpersonation();
    if (config.endpointWithImpersonation === 'POST' && isImpersonating) {
      config.method = 'POST';
      config.data = {
        impersonatedEmail: email,
      };
    }
    if (config.endpointWithImpersonation === 'GET' && isImpersonating) {
      config.params = {
        ...config.params,
        impersonatedEmail: email,
      };
    }
  }

  return config;
});

cloudApiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 419) {
      error.message = 'Your session has expired. Please sign in again.';
      //Sentry.captureException(error);
      if (!error.config.url.includes('logout')) {
        customSignOut();
      }
      return Promise.reject(error);
    }
    if (error.response && error.response.data) {
      const data = error.response.data;

      if (typeof data === 'string') {
        error.message = data;
      } else if (data.message) {
        error.message = data.message;
      } else if (data.error) {
        error.message = data.error;
      }
    }

    if (error.message === 'Network Error') {
      error.message =
        'Cloud API call failed. Check your internet connection. If the issue persists, please contact the customer service.';
    }

    //Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export { cloudApiClient };
