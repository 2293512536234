import { ActionsDropdownWrapper } from '@/app/assets/components/table/columns/assetActionsColumn/ActionsDropdown';
import { OptimizationStatus } from '@/types/rapidModel';
import DeleteItem from './items/DeleteItem';
import CreateEmbededItem from './items/CreateEmbededItem';
import { AssetRow } from '../../hooks/types';
import { Button, Group } from '@mantine/core';
import { IconDownload, IconShare, IconCheck, IconPlus } from '@tabler/icons-react';
import { CONVERTER_ROW, DCC_IMPORTER_ROW } from '../../hooks/useAssetsTable';
import { useDisclosure } from '@mantine/hooks';
import { AddConverterOutputs } from '../../AddConvertedFormatsModal/AddConverterFormatsModal';

export type AssetActionsColumnProps = {
  onDownload: () => void;
  onDelete: () => void;
  onShare: () => void;
  asset: AssetRow;
  shareIsPending: boolean;
  shareIsCopied: boolean;
  showShare?: boolean;
  isAddMoreAvailable: boolean;
};

const deleteAllowedStatuses = new Set<OptimizationStatus>(['done', 'failed']);
const downloadAllowedStatuses = new Set<OptimizationStatus>(['done']);

const isCadOrPbr = (asset: AssetRow) => {
  return asset.basicInfo.rowType === CONVERTER_ROW || asset.basicInfo.rowType === DCC_IMPORTER_ROW;
};

const getIsEmbedAvailable = (asset: AssetRow) => {
  if (!asset.formats) return false;

  const hasGlb =
    Array.isArray(asset.formats) &&
    asset.formats.some((format) => {
      if (typeof format === 'string') {
        return format === 'glb';
      } else if (typeof format === 'object' && 'format' in format) {
        return format.format === 'glb';
      }
      return false;
    });

  return hasGlb && !isCadOrPbr(asset);
};

export function OutputAssetActionsColumn({
  onDownload,
  onDelete,
  onShare,
  asset,
  shareIsPending,
  shareIsCopied,
  showShare,
  isAddMoreAvailable,
}: AssetActionsColumnProps) {
  const [isConvertFormatModalOpened, { open, close }] = useDisclosure();

  const status = asset.optimisationStatus;
  if (!status) {
    return null;
  }

  const isDeleteAvailable = deleteAllowedStatuses.has(status) && !isCadOrPbr(asset);
  const isDownloadAvailable = downloadAllowedStatuses.has(status);
  const isEmbedAvailable = getIsEmbedAvailable(asset);

  return (
    <>
      <Group gap={6} wrap='nowrap'>
        {isDownloadAvailable && (
          <Button
            bg='teal.0'
            radius={36}
            w={40}
            p={0}
            style={{ border: 'none' }}
            onClick={onDownload}
            id={`asset-${asset.basicInfo.id}-download`}
          >
            <IconDownload width={20} stroke={1} />
          </Button>
        )}
        {isEmbedAvailable && showShare && (
          <Button
            bg='teal.0'
            radius={36}
            w={40}
            p={0}
            style={{ border: 'none' }}
            onClick={onShare}
            id={`asset-${asset.basicInfo.id}-share`}
            loading={shareIsPending}
            disabled={shareIsPending || shareIsCopied}
          >
            {shareIsCopied ? <IconCheck width={20} stroke={1} /> : <IconShare width={20} stroke={1} />}
          </Button>
        )}
        {isAddMoreAvailable && (
          <Button
            bg='teal.0'
            radius={36}
            w={40}
            p={0}
            style={{ border: 'none' }}
            id={`asset-${asset.basicInfo.id}-convertMore`}
            loading={shareIsPending}
            disabled={shareIsPending || shareIsCopied}
          >
            <IconPlus width={20} stroke={1} onClick={open} />
          </Button>
        )}
        {(isDeleteAvailable || isEmbedAvailable) && (
          <ActionsDropdownWrapper>
            <DeleteItem onDelete={onDelete} isAvailable={isDeleteAvailable} />
            <CreateEmbededItem assetId={asset.basicInfo.id} isAvailable={isEmbedAvailable} />
          </ActionsDropdownWrapper>
        )}
      </Group>
      {isAddMoreAvailable && (
        <AddConverterOutputs
          key={asset.basicInfo.rowType + asset.basicInfo.id}
          asset={asset}
          close={close}
          opened={isConvertFormatModalOpened}
        />
      )}
    </>
  );
}
