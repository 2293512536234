import { Text, TextProps } from '@mantine/core';

export const OptionalText = ({
  value,
  ...textProps
}: { value?: string | number | null } & TextProps) => {
  if (!value) {
    return null;
  }
  return <Text {...textProps}>{value}</Text>;
};
