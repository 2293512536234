import { AssetRow } from '@/app/assets/components/table/hooks/types';
import { memo } from 'react';

type RawModelPreviewProps = {
  rowType: AssetRow['basicInfo']['rowType'];
  thumbnail: AssetRow['basicInfo']['thumbnail'];
  disabled: boolean;
  useOnlyOutputs: boolean;
};

function RawModelPreview({ rowType, disabled, thumbnail, useOnlyOutputs }: RawModelPreviewProps) {
  if (disabled || !thumbnail) {
    return null;
  }

  if (rowType !== 'raw' && !useOnlyOutputs) {
    return null;
  }

  // eslint-disable-next-line @next/next/no-img-element
  return <img src={thumbnail} alt='Asset thumbnail' width={60} height={60} />;
}

export default memo(RawModelPreview);
