import { GetRapidModelResponse } from '@/types/rapidModel';
import { getSearchQuery } from '@/utils/getSearchQuery';
import { mapRapidModel } from './rapidModel';
import { RawModel } from '@/types/rawModel';
import { cloudApiClient } from '@/services/clientSideCloudApiService';

type PagedRapidModelResponse = GetRapidModelResponse & {
  meta: { current_page: number; per_page: number; last_page: number };
};

export const getOutputModels = async (search?: string, page?: number) => {
  const params = getSearchQuery(search, page);
  const { data: rapidModels } = await cloudApiClient.get<PagedRapidModelResponse>('rapidmodel' + params, {
    endpointWithImpersonation: 'POST',
  });

  const mappedModels = await Promise.all(rapidModels.data.map(mapRapidModel));

  //A little bit hacky adapter
  const fakeAsset: RawModel = {
    rapidModels: mappedModels,
    assetType: 'raw',
    upload_status: 'complete',
    errors: [],
    created_at: '',
    format: '',
    id: -1,
    name: '',
    rpdInfo: { polygons: 0 },
    scale_factor: 0,
    size: 0,
    warnings: [],
  };

  return {
    currentPage: rapidModels.meta.current_page,
    pageSize: rapidModels.meta.per_page,
    totalPages: rapidModels.meta.last_page,
    data: [fakeAsset],
  };
};
