import { useQuery } from '@tanstack/react-query';
import { getApiTokens } from '../actions/apiTokens';

export const API_TOKENS_QUERY_KEY = 'apiTokens';
export const apiTokensQueryKey = [API_TOKENS_QUERY_KEY];

export function useApiTokensQuery() {
  return useQuery({
    queryKey: apiTokensQueryKey,
    queryFn: async () => {
      return await getApiTokens();
    },
    throwOnError: false,
  });
}
