import { MAIN_ASSET_QUERY_KEY } from '@/app/assets/hooks/useAssetsQuery';
import { USER_QUERY_KEY, useUserQuery } from '@/shared/hooks/useUserQuery';
import { Button, Divider, Grid, Group, Modal, Progress, Stack, Text } from '@mantine/core';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { AssetRow } from '../hooks/types';
import { AvailableFormatToConvert } from './atoms/AvailableFormatToConvert';
import { ConvertedFormat } from './atoms/ConvertedFormat';
import { useConvertMoreFormats } from './hooks/useConvertMoreFormats';
import { useProcessedFormatsQuery } from './hooks/useProcessedFormats';
import { showErrorNotification } from '@/app/components/shared/notifications';
//import { useRawAssetQuery } from './hooks/useRawAssetQuery';

//Should be in sync with - search codebase for phrase SOMETHINGCNMN1618
export const formatsToConversion = ['gltf', 'glb', 'usd', 'usda', 'usdc', 'usdz', 'fbx', 'obj', 'ply', 'ctm', 'stl'];

export const AddConverterOutputs = ({
  asset,
  close,
  opened,
}: {
  asset: AssetRow;
  close: () => void;
  opened: boolean;
}) => {
  const { data: user } = useUserQuery();
  const storageLeft = user?.plan.storage_left ?? 0;
  const storageLimit = user?.plan.details.storage_limit ?? 0;
  const storageLimitB = storageLimit * 1024 * 1024 * 1024;
  const storagePercentage = Number(Math.max(100 - (storageLeft / storageLimitB) * 100, 0)).toFixed(0);

  const presentFormats =
    asset.formatConverterMetadata?.formats ??
    asset.formats?.map((stringOrObject) =>
      typeof stringOrObject === 'string' ? stringOrObject : stringOrObject.format
    ) ??
    [];

  const notAlreadyPresent = formatsToConversion.filter(
    (format) => !presentFormats.includes(format) && !asset.formatConverterMetadata?.omitFormats?.includes(format)
  );

  const [selected, setSelected] = useState<string[]>([]);
  const [pollingEnabled, setPollingEnabled] = useState(false);

  // Leaving it in the code so if we will need to await size change in the future we can use it as reference
  // const { data: assetData } = useRawAssetQuery({
  //   assetId: asset.basicInfo.id,
  //   pollingEnabled: pollingEnabled,
  // });

  const queryClient = useQueryClient();
  const isFetchingAssets = !!useIsFetching({ queryKey: [MAIN_ASSET_QUERY_KEY] });

  const { data, refetch } = useProcessedFormatsQuery({
    assetId: asset.basicInfo.id,
    pollingEnabled: pollingEnabled,
  });
  const processedFormats = data?.inProgress ?? [];
  const failedJobsCount = data?.failedJobsCount ?? Infinity;
  const prevFailedJobsCount = useRef(failedJobsCount);

  useEffect(() => {
    if (failedJobsCount > prevFailedJobsCount.current) {
      showErrorNotification({
        message: 'Adding new formats failed. Please try again later. If the problem persists, contact support.',
      });
      setPollingEnabled(false);
    }
    prevFailedJobsCount.current = failedJobsCount;
  }, [failedJobsCount]);

  const [progress, setProgress] = useState(data?.progress ?? 0);

  //Used to make progress stable
  useEffect(() => {
    const currentProgress = data?.progress ?? 0;
    if (currentProgress > progress) {
      setProgress(currentProgress);
    }
  }, [data, progress]);

  const { mutate: convertMoreFormats, isPending } = useConvertMoreFormats(asset.basicInfo.id, {
    onSuccess: () => {
      refetch();
      setPollingEnabled(true);
    },
  });

  // Leaving it in the code so if we will need to await size change in the future we can use it as reference
  // const prevAssetSize = useRef(asset.size);
  // useEffect(() => {
  //   if (assetData?.size && assetData.size !== prevAssetSize.current) {
  //     setProgress(100);
  //     refetch();
  //     queryClient.invalidateQueries({ queryKey: [MAIN_ASSET_QUERY_KEY] });
  //     queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] });
  //     setSelected([]);
  //     setPollingEnabled(false);
  //   }
  //   prevAssetSize.current = assetData?.size ?? asset.size;
  // }, [assetData?.size, asset.size, queryClient, refetch]);

  const prevProcessedLength = useRef(processedFormats.length);
  useEffect(() => {
    if (processedFormats.length < prevProcessedLength.current) {
      setProgress(100);
      queryClient.invalidateQueries({ queryKey: [MAIN_ASSET_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] });
      setSelected([]);
      setPollingEnabled(false);
    }
    prevProcessedLength.current = processedFormats.length;
  }, [processedFormats.length]);

  useEffect(() => {
    if (processedFormats.length > 0) {
      setPollingEnabled(true);
    }
  }, [processedFormats.length]);

  const addSelected = (format: string) => {
    setSelected([...selected, format]);
  };

  const removeSelected = (format: string) => {
    setSelected(selected.filter((selectedFormat) => selectedFormat !== format));
  };

  const isProgressing = pollingEnabled || isFetchingAssets;

  return (
    <Modal
      size='700'
      title={
        <Text size='lg' fw={600}>
          Select export formats
        </Text>
      }
      classNames={{
        root: 'h-full',
        content: 'flex flex-col',
        body: 'flex flex-col h-full  pb-0',
        header: 'py-0',
      }}
      opened={opened}
      onClose={close}
    >
      <Divider mx={-24} mb='sm' />
      <Stack gap='xs'>
        <Group justify='space-between'>
          <Text>Convert your model to other formats.</Text>
          <Group gap='xs'>
            <Text c='gray.6'>Available Storage:</Text>
            <Text>
              {storageLimit}GB ({storagePercentage}% used)
            </Text>
          </Group>
        </Group>
        <Progress value={progress} opacity={isProgressing ? 1 : 0} />
        <Grid gutter='xs' align='center' columns={18}>
          <Grid.Col pt='xs' style={{ alignSelf: 'flex-start' }} span={5}>
            <Text c='gray.6'>Exported formats:</Text>
          </Grid.Col>
          <Grid.Col span={13}>
            <Group gap='xs'>
              {presentFormats.map((format) => (
                <ConvertedFormat key={format} format={format} asset={asset} />
              ))}
            </Group>
          </Grid.Col>
          <Grid.Col pt='xs' style={{ alignSelf: 'flex-start' }} span={5}>
            <Text c='gray.6'>Select other formats:</Text>
          </Grid.Col>
          <Grid.Col span={13}>
            <Group gap='xs'>
              {notAlreadyPresent.map((format) => {
                const isSelected = selected.includes(format);
                const isPending = processedFormats.includes(format);
                return (
                  <AvailableFormatToConvert
                    onClick={() => (isSelected ? removeSelected(format) : addSelected(format))}
                    format={format}
                    key={format}
                    isSelected={isSelected}
                    isPending={!!isPending}
                  />
                );
              })}
            </Group>
          </Grid.Col>
        </Grid>
        <Button style={{ alignSelf: 'self-end' }} variant='transparent' onClick={() => setSelected([])}>
          Remove all
        </Button>
      </Stack>
      <Divider mx={-24} mt='sm' />
      <Group gap='xs' my='sm' justify='flex-end'>
        <Button onClick={close} variant='default'>
          Cancel
        </Button>
        <Button
          loading={isPending || isProgressing}
          onClick={() => {
            setProgress(0);
            if (selected.length === 0) {
              return showErrorNotification({ message: 'Please select at least one format to convert!' });
            }
            convertMoreFormats(selected);
          }}
        >
          Add
        </Button>
      </Group>
    </Modal>
  );
};
