import { cloudApiClient } from '@/services/clientSideCloudApiService';
import { ApiUser, User } from '@/types/user';

type GetUserResponse = {
  data: ApiUser;
};

export const getUser = async function (): Promise<User> {
  const { data: user } = await cloudApiClient.get<GetUserResponse>('user');

  return {
    ...user.data,
    hasActivePBR: user.data.plan?.details?.rapidpbr_support,
  };
};

export type ImpersonationErrorObj = {
  error: {
    message: string;
  };
};

export const getUserWithImpersonation = async function (
  impersonatedEmail?: string
): Promise<User | ImpersonationErrorObj> {
  const { data: user } = await cloudApiClient.post<GetUserResponse>('user', { impersonatedEmail });

  return {
    ...user.data,
    hasActivePBR: user.data.plan?.details?.rapidpbr_support,
  };
};
