import { WorkflowType } from '../actions/constants';
import { WorkflowTab } from './navigation';

export const IdsMapper = {
  [WorkflowTab.DEVICE]: [
    2015, 2016, 2017, 2024, 2025, 2026, 4001, 4002, 4003, 2012, 2013, 2014, 7000, 7006, 6877, 6295,
  ],
  [WorkflowTab.APPLICATION]: [
    2003, 2004, 2005, 3001, 2002, 3002, 2009, 2010, 2011, 2000, 2001, 3000, 3003, 3004, 3005, 2018, 2019, 2020, 2006,
    2007, 2008, 3006, 3007, 3008, 2021, 2022, 2023, 7005, 6290, 6887, 6382,
  ],
  [WorkflowTab.GENERAL]: [
    5000, 5001, 5002, 5003, 5004, 5005, 5006, 5007, 5008, 5009, 5010, 5011, 5012, 5013, 5014, 7001, 7002, 7003, 7004,
    7007, 7008, 6311, 6341, 6336, 6469, 6335, 6424, 6868, 6870, 6430, 6608, 6865, 6304, 6301, 6291,
  ],
};
const factoryIds = Object.values(IdsMapper).flat();

export const getGeneralWorkflows = (workflow: { id: number }) => IdsMapper[WorkflowTab.GENERAL].includes(workflow.id);

export const getApplicationWorkflows = (workflow: { id: number }) =>
  IdsMapper[WorkflowTab.APPLICATION].includes(workflow.id);

export const getDeviceWorkflows = (workflow: { id: number }) => IdsMapper[WorkflowTab.DEVICE].includes(workflow.id);

export const isFactoryPreset = (workflow: { id: number }) => factoryIds.includes(workflow.id);

export const getWorkflowType = (workflow: { id: number }): WorkflowType => {
  const { id } = workflow;

  if (IdsMapper[WorkflowTab.GENERAL].includes(id)) {
    return WorkflowType.GENERAL;
  } else if (IdsMapper[WorkflowTab.APPLICATION].includes(id)) {
    return WorkflowType.APPLICATION;
  } else if (IdsMapper[WorkflowTab.DEVICE].includes(id)) {
    return WorkflowType.DEVICE;
  } else {
    return WorkflowType.GENERAL;
  }
};
