import { getMeshResolution } from '../../components/v6/organism/utils/getMeshResolution';
import { getWorkflowType } from '../../utils/workflowTypes';
import { WorkflowType } from '../constants';
import { ApiWorkflow, InvalidWorkflow, Workflow, WorkflowConfig } from '../interfaces';
import { getInitialEnabledOperations } from '@/app/workflows/actions/utils/getEnabledOperations';
import { NO_DESCRIPTION, factoryWorkflowDescriptions } from './workflowDescriptions';
import { getFileFormatsV7, getMeshResolutionV7, getTextureResolutionsV7 } from './getPresetDetailsV7';
import { isRpdx7 } from '../../utils/isRpdx7';

function getFactoryDescription(workflow: ApiWorkflow): string {
  // Remove resolution part to match the factory presets descriptions
  const baseName = workflow.name.replace(/Mid Res$/, '').trim();

  // Use the factory preset description if available
  let factoryDescription = factoryWorkflowDescriptions[baseName] || NO_DESCRIPTION;
  if (typeof factoryDescription !== 'string') {
    const schemaVersion = `v${workflow.rpdx_version?.split('.')[0]}`;
    factoryDescription =
      schemaVersion in factoryDescription
        ? (factoryDescription as Record<string, string>)[schemaVersion]
        : Object.values(factoryDescription)[0];
  }

  // Combine the factory preset description with the workflow description
  if (factoryDescription !== NO_DESCRIPTION) {
    return workflow.description ? `${factoryDescription} ${workflow.description}` : factoryDescription;
  } else {
    // Use the workflow description if no factory preset description is available
    return workflow.description || NO_DESCRIPTION;
  }
}
function getTextureResolutions(config: WorkflowConfig) {
  if (config.workflowSettings) {
    return [...new Set(Object.values(config.workflowSettings.targetTextureResolution ?? {}))];
  }

  if (config.limits) {
    return [...new Set(Object.values(config.limits?.textures ?? {}))];
  }

  return [];
}

export const mapApiWorkflowToWorkflow = (
  workflow: ApiWorkflow,
  useFactoryDescriptions: boolean = false
): Omit<Workflow, 'clientMetadata'> | InvalidWorkflow => {
  try {
    const config = JSON.parse(workflow.config) as WorkflowConfig;

    const description = useFactoryDescriptions
      ? getFactoryDescription(workflow)
      : workflow.description || NO_DESCRIPTION;

    if (isRpdx7(workflow.rpdx_version)) {
      return {
        id: workflow.id,
        name: workflow.name,
        description,
        fileFormats: getFileFormatsV7(config),
        textureResolutions: getTextureResolutionsV7(config),
        meshResolution: getMeshResolutionV7(config),
        config,
        meta: JSON.parse(workflow.meta ?? '{}'),
        rpdxVersion: workflow.rpdx_version,
      };
    }

    return {
      id: workflow.id,
      name: workflow.name,
      description,
      fileFormats: config.compressionAndExport.fileExports.map((ex) => ex.fileType),
      textureResolutions: getTextureResolutions(config).sort(),
      meshResolution: getMeshResolution(config),
      config,
      meta: JSON.parse(workflow.meta ?? '{}'),
      rpdxVersion: workflow.rpdx_version ?? '6.0.0',
    };
  } catch (e) {
    return {
      id: workflow?.id ?? -1,
      name: workflow?.name ?? 'Unknown',
      invalid: true,
    };
  }
};

export const mapAsCustomerWorkflow = (
  workflow: Omit<Workflow, 'clientMetadata'>,
  isImpersonating?: boolean
): Workflow => {
  const enabledOperations = isImpersonating ? [] : getInitialEnabledOperations(workflow.config);

  return {
    ...workflow,
    clientMetadata: {
      enabledOperations,
      type: WorkflowType.CUSTOM,
    },
  };
};

export const asFactoryWorkflow = (workflow: Omit<Workflow, 'clientMetadata'>, isImpersonating?: boolean): Workflow => {
  const enabledOperations = isImpersonating ? [] : getInitialEnabledOperations(workflow.config);

  return {
    ...workflow,
    clientMetadata: {
      enabledOperations,
      type: getWorkflowType(workflow),
    },
  };
};
