import { cloudApiClient } from '@/services/clientSideCloudApiService';
import { ApiToken, CreateApiToken } from './interfaces';

export const createApiToken = async function (token: CreateApiToken) {
  const response = await cloudApiClient.post<string>('user/tokens/create', token);
  return { token: response.data };
};

export const revokeApiToken = async function (id: number) {
  const result = await cloudApiClient.delete<string>(`user/tokens/${id}`);
  return result.data;
};

export const getApiTokens = async function () {
  const { data: result } = await cloudApiClient.get('user/tokens', { endpointWithImpersonation: 'POST' });
  return result.data as ApiToken[];
};
