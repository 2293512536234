import { laravelLogout } from '@/app/logout';
import { signOut } from 'next-auth/react';
import { Crisp } from 'crisp-sdk-web';
import { removeImpersonationCookie } from './impersonation';

export const customSignOut = async () => {
  removeImpersonationCookie();
  await laravelLogout();
  await signOut({ redirect: true });
  Crisp.setTokenId();
  Crisp.session.reset();
};
