import { RapidModel } from '@/types/rapidModel';
import { RawModel } from '@/types/rawModel';
import { useQueryClient } from '@tanstack/react-query';
import { MAIN_ASSET_QUERY_KEY } from '../../hooks/useAssetsQuery';
import { Page } from '../../../../types/pagination';

export const useOptimisticAssetDetails = (asset?: RapidModel | RawModel) => {
  const onAddTag = (tag: string) => {
    queryClient.setQueriesData(
      { predicate: (query) => query.queryKey.includes(MAIN_ASSET_QUERY_KEY) },
      (oldData: Page<RawModel> | undefined) => {
        if (!oldData) {
          return oldData;
        }

        const rawModelIndex = oldData.data.findIndex((oldAsset) => oldAsset.id === asset?.id);

        if (rawModelIndex === -1) {
          const parentModelIndex = oldData.data.findIndex(
            (rawModel) => rawModel.rapidModels.findIndex((rapidModel) => rapidModel.id === asset?.id) !== -1
          );

          if (parentModelIndex === -1) {
            return oldData;
          }
          
          const parentRawModel = oldData.data[parentModelIndex];
          const rapidModelIndex = parentRawModel.rapidModels.findIndex((rapidModel) => rapidModel.id === asset?.id);

          if (rapidModelIndex === -1) {
            return oldData;
          }

          const rapidModel = parentRawModel.rapidModels[rapidModelIndex];
          rapidModel.tags = [
            ...(rapidModel.tags ?? []),
            {
              created_at: new Date().toISOString(),
              id: Math.random() * 1000,
              name: tag,
              updated_at: new Date().toISOString(),
            },
          ];

          return JSON.parse(JSON.stringify(oldData));
        }

        const rawModel = oldData.data[rawModelIndex];
        rawModel.tags = [
          ...(rawModel.tags ?? []),
          {
            created_at: new Date().toISOString(),
            id: Math.random() * 1000,
            name: tag,
            updated_at: new Date().toISOString(),
          },
        ];

        return JSON.parse(JSON.stringify(oldData));
      }
    );
  };

  const onRemoveTag = (id: number) => {
    queryClient.setQueriesData(
      { predicate: (query) => query.queryKey.includes(MAIN_ASSET_QUERY_KEY) },
      (oldData: Page<RawModel> | undefined) => {
        if (!oldData) {
          return oldData;
        }

        const index = oldData.data.findIndex((oldAsset) => oldAsset.id === asset?.id);

        if (index === -1) {
          const parentModelIndex = oldData.data.findIndex(
            (rawModel) => rawModel.rapidModels.findIndex((rapidModel) => rapidModel.id === asset?.id) !== -1
          );

          if (parentModelIndex === -1) {
            return oldData;
          }

          const parentRawModel = oldData.data[parentModelIndex];
          const rapidModelIndex = parentRawModel.rapidModels.findIndex((rapidModel) => rapidModel.id === asset?.id);

          if (rapidModelIndex === -1) {
            return oldData;
          }

          const rapidModel = parentRawModel.rapidModels[rapidModelIndex];
          rapidModel.tags = [...(rapidModel.tags ?? [])].filter((oldTag) => oldTag.id !== id);

          return JSON.parse(JSON.stringify(oldData));
        }

        const rawModel = oldData.data[index];
        rawModel.tags = [...(rawModel.tags ?? [])].filter((oldTag) => oldTag.id !== id);

        return JSON.parse(JSON.stringify(oldData));
      }
    );
  };

  const onNameChange = (id: number, name: string) => {
    queryClient.setQueriesData(
      { predicate: (query) => query.queryKey.includes(MAIN_ASSET_QUERY_KEY) },
      (oldData: Page<RawModel> | undefined) => {
        if (!oldData) {
          return oldData;
        }

        const index = oldData.data.findIndex((oldAsset) => oldAsset.id === id);

        if (index === -1) {
          return oldData;
        }

        oldData.data[index].name = name;
        return JSON.parse(JSON.stringify(oldData));
      }
    );
  };

  const queryClient = useQueryClient();

  return { onAddTag, onRemoveTag, onNameChange };
};
