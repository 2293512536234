import { AlertProps, List, Text } from '@mantine/core';
import { ErrorAlertWithButton, WarningAlert } from '@/app/components/alerts/alerts';
import { ModelDescriptor } from '@/app/components/supportTicket/types';

export const WarningList = ({ warnings, ...rest }: { warnings: string[] } & AlertProps) => (
  <WarningAlert {...rest} title='Warning'>
    <List>
      {warnings.map((warning) => (
        <List.Item key={warning}>
          <Text size='sm'>{warning}</Text>
        </List.Item>
      ))}
    </List>
  </WarningAlert>
);

export const ErrorList = ({
  errors,
  assetDescription,
  ...rest
}: { errors: string[]; assetDescription?: ModelDescriptor } & AlertProps) => (
  <ErrorAlertWithButton {...rest} title='Error' assetDescription={assetDescription}>
    <List>
      {errors.map((error) => (
        <List.Item key={error}>
          <Text size='sm'>{error}</Text>
        </List.Item>
      ))}
    </List>
  </ErrorAlertWithButton>
);
