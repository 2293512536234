export const truncateString = (str: string, length: number) => {
  if (str.length <= length) {
    return str;
  }
  return str.slice(0, length) + '…';
};

export const truncateStringInMiddle = (str: string, {breakPoint, maxLength}: {breakPoint: number, maxLength: number}) => {
  if (str.length <= breakPoint) {
    return str;
  }

  const truncationIndicator = '…';
  const truncationLength = str.length - maxLength;
  const midpoint = Math.floor(str.length / 2);

  const truncatedString =
    str.slice(0, midpoint - Math.floor(truncationLength / 2)) +
    truncationIndicator +
    str.slice(midpoint + Math.ceil(truncationLength / 2));

  return truncatedString;
};