import { Button, Group, Menu } from '@mantine/core';
import CircleIcon from '@/icons/circle.svg';
import { PropsWithChildren } from 'react';

export function ActionsDropdownWrapper({ children }: PropsWithChildren) {
  return (
    <Menu shadow='md'>
      <Menu.Target>
        <Button bg='teal.0' radius={36} w={40} p={0} style={{ border: 'none' }}>
          <Group wrap='nowrap' gap={1} w={25}>
            {Array.from({ length: 3 }, (_, i) => i).map((i) => (
              <CircleIcon
                key={i}
                className='stroke-current stroke-1'
                color='var(--mantine-color-dark-9)'
              />
            ))}
          </Group>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  );
}
