'use client';

import { ScrollableModal } from '@/app/components/modal/ScrollableModal';
import { Box, Button, Group, Text } from '@mantine/core';
import { ExternalLink } from '@/app/components/shared/ExternalLink';
import externalLinks from '@/utils/externalLinks';
import { SupportTicketForm } from '@/app/components/supportTicket/SupportTicketForm';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { useCreateSupportRequest } from '@/app/components/supportTicket/hooks/useCreateSupportRequest';
import { ModelDescriptor } from './types';
import { useEffect } from 'react';
import { useUserQuery } from '@/shared/hooks/useUserQuery';

interface SupportTicketModalProps {
  onClose: () => void;
  opened: boolean;
  initModel?: ModelDescriptor;
}

const supportModelSchema = z.object({
  id: z.number(),
  name: z.string(),
  type: z.union([z.literal('raw'), z.literal('rapid')]),
});

const supportRequestFormSchema = z.object({
  subject: z.string().min(2, 'Subject is required'),
  message: z.string().min(2, 'Message is required'),
  models: supportModelSchema.array(),
});

export type SupportRequestFormValues = z.infer<typeof supportRequestFormSchema>;

export function SupportTicketModal({ onClose, opened, initModel }: SupportTicketModalProps) {
  const { data: user } = useUserQuery();
  const { sendRequest, isPending } = useCreateSupportRequest(user, () => handleOnClose());

  const form = useForm<SupportRequestFormValues>({
    initialValues: {
      models: [],
      message: '',
      subject: '',
    },
    validate: zodResolver(supportRequestFormSchema),
  });

  useEffect(() => {
    if (opened && form.getValues().models.length === 0 && initModel) {
      form.setFieldValue('models', initModel ? [initModel] : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- required to do this only once on open
  }, [opened]);

  const handleOnClose = () => {
    onClose();
    form.reset();
  };

  return (
    <ScrollableModal
      size={600}
      onClose={handleOnClose}
      opened={opened}
      title='Do you have a question?'
      nonScrollableBottom={
        <Group justify='end' gap='xs' h={60}>
          <Button variant='default' onClick={handleOnClose} disabled={isPending}>
            Cancel
          </Button>
          <Button
            type='submit'
            loading={isPending}
            onClick={() =>
              form.onSubmit((values) => {
                sendRequest(values);
              })()
            }
          >
            Send
          </Button>
        </Group>
      }
    >
      <Box my='sm'>
        <Text mb='xs'>
          Please note that this form will submit a ticket to our support system. You can submit your ticket directly
          there by clicking{' '}
          <ExternalLink href={externalLinks.supportHome()} target='_blank'>
            here.
          </ExternalLink>
        </Text>
        <Text>
          If you have problems with specific models, you can select them via the search field and attach them to the
          ticket.
        </Text>
        <SupportTicketForm form={form} />
      </Box>
    </ScrollableModal>
  );
}
