import { getUser } from '@/app/(actions)/user/getUser';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

export const USER_QUERY_KEY = 'user';

export const useUserQuery = () => {
  const { data } = useSession();

  return useQuery({
    queryKey: [USER_QUERY_KEY],
    queryFn: async () => await getUser(),
    enabled: !!data?.user,
    throwOnError: false,
  });
};
