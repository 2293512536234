import { RawModel, RawModelPbr } from '@/types/rawModel';
import { notNull } from '@/utils/notNull';

const imageFormats = ['png', 'jpg', 'jpeg', 'avif', 'webp', 'gif'];

const isImage = (fileName: string) => imageFormats.some((f) => fileName.includes(f));

export function getAssetImages(asset: RawModel, pbr?: RawModelPbr): { title: string; src: string }[] {
  const qcImages = Object.entries(pbr?.qc ?? {})
    .filter(([fileName]) => isImage(fileName))
    .map(([fileName, signedUrl]) => ({
      title: fileName,
      src: signedUrl as string,
    }));

  const pbrMaterials = Object.entries(pbr?.materials ?? {})
    .filter(([fileName]) => isImage(fileName))
    .map(([fileName, signedUrl]) => ({
      title: fileName,
      src: signedUrl as string,
    }));

  const sortedQCList = qcImages.sort((a, b) => {
    const aIsRenderGltf = a.title.startsWith('render_') && a.title.includes('_gltf');
    const bIsRenderGltf = b.title.startsWith('render_') && b.title.includes('_gltf');
    const aIsRender = a.title.startsWith('render_');
    const bIsRender = b.title.startsWith('render_');
    const aIsDiff = a.title.startsWith('diff_');
    const bIsDiff = b.title.startsWith('diff_');

    if (aIsRenderGltf && !bIsRenderGltf) {
      return -1;
    } else if (!aIsRenderGltf && bIsRenderGltf) {
      return 1;
    }

    if (aIsRender && !bIsRender) {
      return -1;
    } else if (!aIsRender && bIsRender) {
      return 1;
    }

    if (aIsDiff && !bIsDiff) {
      return -1;
    } else if (!aIsDiff && bIsDiff) {
      return 1;
    }

    return a.title.localeCompare(b.title);
  });

  const thumb = asset.downloads?.['thumb.jpg'] ? { title: 'Thumbnail', src: asset.downloads?.['thumb.jpg'] } : null;

  return [...(thumb ? [thumb] : []), ...sortedQCList, ...pbrMaterials].filter((i) => i.src);
}

export function getPBROutputsData(asset: RawModel, pbr?: RawModelPbr) {
  const formats = ['fbx', 'glb', 'gltf', 'max', 'usdz'].filter((format) => !!pbr?.[format]);

  return {
    formats,
    downloadFiles: getPBROutputsForDownload(asset),
  };
}

export function getPBROutputsForDownload(asset: RawModel) {
  const convertedValues = [asset.converted_fbx, asset.converted_glb, asset.converted_gltf, asset.converted_max, asset.converted_usdz].filter(
    notNull
  );

  return convertedValues.map((converted) => ({
    name: converted.name,
    url: converted.url,
  }));
}
