import { Text } from '@mantine/core';

import { MouseEvent } from 'react';
import { FormatBadge } from './FormatBadge';
interface FormatsExpanderProps {
  hiddenFormats: string[];
  onDownload: (format: string) => void;
  setIsExpanded: (value: boolean) => void;
  isExpanded: boolean;
}

export function FormatsExpander({ hiddenFormats, onDownload, setIsExpanded, isExpanded }: FormatsExpanderProps) {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsExpanded(true);
  };

  if (!isExpanded) {
    return (
      <FormatBadge onClick={onClick} style={{ cursor: 'pointer' }}>
        <Text>+{hiddenFormats.length}</Text>
      </FormatBadge>
    );
  }

  return (
    <>
      {hiddenFormats.map((format) => (
        <FormatBadge
          key={format}
          onClick={(e) => {
            e.stopPropagation();
            onDownload(format);
          }}
        >
          <Text>{format}</Text>
        </FormatBadge>
      ))}
    </>
  );
}
