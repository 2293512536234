import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PublishedPageData } from '../interfaces/api';
import { useSearchParams } from 'next/navigation';
import { getSearchParam } from '@/utils/getSearchParam';
import { PAGE_QUERY_KEY, SEARCH_QUERY_KEY } from '../constants';
import { getPublishedPage } from '../actions/getPublishedModels';

export const MAIN_PUBLISHED_QUERY_KEY = 'published';

export const usePublishedKey = () => {
  const { showOneClick } = usePublishedParams();
  return [MAIN_PUBLISHED_QUERY_KEY, showOneClick];
};

export const usePublishedParams = () => {
  const searchParams = useSearchParams();

  const query = getSearchParam(searchParams.get(SEARCH_QUERY_KEY));
  const page = getSearchParam(searchParams.get(PAGE_QUERY_KEY));
  const showOneClick = getSearchParam(searchParams.get('showOneClick'));

  return {
    query,
    page: page ? +page : undefined,
    showOneClick: showOneClick ? showOneClick === 'true' : undefined,
  };
};

export const usePublishedModelsQuery = (options?: Omit<UseQueryOptions<PublishedPageData>, 'queryKey' | 'queryFn'>) => {
  const { page, query, showOneClick } = usePublishedParams();
  const key = usePublishedKey();

  return useQuery({
    ...options,
    queryKey: key,
    queryFn: async () => {
      const published = await getPublishedPage({ query, page, showOneClick });
      return published;
    },
    structuralSharing: false,
    placeholderData: (prev) => prev,
    throwOnError: false,
  });
};
