import { AxiosProgressEvent } from 'axios';
import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';
import { getBlob } from '@/app/components/shared/fileDownload/utils';
import { useThrottledCallback } from 'use-debounce';
import * as zip from '@zip.js/zip.js';
import { UpdateProgressArgs } from '@/app/assets/components/table/hooks/download/useDownloadProgress';

export type Progress = {
  progress: number;
  finishedFiles: number;
  totalFiles: number;
  files: Record<string, number>;
};

export const useZipDownload = () => {
  const [progress, setProgress] = useState<Progress>({
    progress: 0,
    finishedFiles: 0,
    totalFiles: 0,
    files: {},
  });
  const [isPending, setIsPending] = useState(false);

  const throttledProgressUpdate = useThrottledCallback(
    (args: UpdateProgressArgs & { withZipping?: boolean }, name: string) => {
      const { axiosProgressEvent, zippingProgress, withZipping } = args;
      setProgress((s) => {
        const files = {
          ...s.files,
          ...{
            [name]: axiosProgressEvent
              ? (axiosProgressEvent.progress ?? 100) / (withZipping ? 2 : 1)
              : 0.5 + zippingProgress / 2,
          },
        };

        return {
          ...s,
          files,
          finishedFiles:
            s.finishedFiles +
            (withZipping ? (zippingProgress === 1 ? 1 : 0) : axiosProgressEvent?.progress === 1 ? 1 : 0),
          progress: Object.values(files).reduce((acc, next) => acc + next, 0) / s.totalFiles,
        };
      });
    },
    300
  );

  const download = useCallback(
    async (
      files: { name: string; orgName?: string; url: string }[],
      downloadName?: string,
      addZipExtension?: boolean
    ) => {
      setIsPending(true);
      setProgress({
        finishedFiles: 0,
        progress: 0,
        files: {},
        totalFiles: files.length,
      });

      try {
        if (files.length > 1) {
          const blobWriter = new zip.BlobWriter('application/zip');
          const zipWriter = new zip.ZipWriter(blobWriter);

          // Step 1: Generate unique names for all files
          const usedNames = new Set();
          const filesWithUniqueNames = files.map((file) => {
            const { name, orgName } = file;
            // for rawmodels we don't need to add .zip extension
            let zipName = name;
            if (addZipExtension && orgName?.endsWith('.zip')) {
              // for outputs/rapidmodels and the original file name we need to add .zip extension
              zipName = `${name}.zip`;
            }

            // Split into base and extension
            const [base, ext] = splitExtension(zipName);
            let uniqueName = zipName;
            let counter = 1;

            // If the name is already used, append a counter until it's unique
            while (usedNames.has(uniqueName)) {
              uniqueName = `${base} (${counter})${ext}`;
              counter++;
            }
            usedNames.add(uniqueName);

            // Return the file object with its unique zip name
            return { ...file, uniqueZipName: uniqueName };
          });

          // Step 2: Process files with unique names
          await Promise.all(
            filesWithUniqueNames.map(async ({ url, uniqueZipName }) => {
              const blob = await getBlob(url, (axiosProgressEvent) =>
                throttledProgressUpdate({ axiosProgressEvent, withZipping: true }, url)
              );
              await zipWriter.add(uniqueZipName, new zip.BlobReader(blob), {
                onprogress: async (current, total) => {
                  throttledProgressUpdate({ zippingProgress: current / total, withZipping: true }, url);
                },
              });
              throttledProgressUpdate({ zippingProgress: 1, withZipping: true }, url);
            })
          );

          await zipWriter.close();
          const zipped = await blobWriter.getData();
          saveAs(zipped, downloadName ?? 'download.zip');
        } else {
          const blob = await getBlob(files[0].url, (axiosProgressEvent) =>
            throttledProgressUpdate({ axiosProgressEvent }, files[0].url)
          );

          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          a.href = url;

          let name = files[0].name;
          if (addZipExtension && files[0].orgName?.endsWith('.zip')) {
            name = `${name}.zip`;
          }

          a.download = name;
          a.click();
        }
      } finally {
        setIsPending(false);
      }
    },
    [throttledProgressUpdate]
  );

  return { isPending, progress, download };
};

function splitExtension(fileName: string) {
  const dotIndex = fileName.lastIndexOf('.');
  if (dotIndex > 0 && dotIndex < fileName.length - 1) {
    return [fileName.substring(0, dotIndex), fileName.substring(dotIndex)];
  } else {
    return [fileName, ''];
  }
}
