import { Alert, AlertProps, Button, Group } from '@mantine/core';
import { PropsWithChildren } from 'react';
import WarningIcon from '@/icons/warning.svg';
import CircleIcon from '@/icons/circle.svg';
import { useCreateSupportTicket } from '@/app/contexts/CreateSupportTicketContext';
import { ModelDescriptor } from '../supportTicket/types';

type AlertComponentProps = PropsWithChildren & { title?: string } & AlertProps;

const commonAlertStyles = { icon: { marginInlineEnd: 16, marginTop: 3 }, root: { padding: 12 } };

export function ErrorAlert({ children, title }: AlertComponentProps) {
  return (
    <Alert title={title} icon={<WarningIcon />} color='red' styles={commonAlertStyles} p='sm'>
      {children}
    </Alert>
  );
}

export function WarningAlert({ children, title, ...rest }: AlertComponentProps) {
  return (
    <Alert title={title} icon={<CircleIcon />} color='orange' p='sm' {...rest} styles={commonAlertStyles}>
      {children}
    </Alert>
  );
}

export function InfoAlert({ children, title }: AlertComponentProps) {
  return (
    <Alert title={title} icon={<CircleIcon />} color='blue' p='sm' styles={commonAlertStyles}>
      {children}
    </Alert>
  );
}

export function ErrorAlertWithButton({
  children,
  title,
  assetDescription,
  ...rest
}: AlertComponentProps & {
  assetDescription?: ModelDescriptor;
}) {
  const { open } = useCreateSupportTicket();
  return (
    <Alert
      title={title}
      icon={<WarningIcon />}
      color='red'
      p='sm'
      {...rest}
      styles={{ ...commonAlertStyles, icon: { marginTop: 0 } }}
    >
      {children}
      <Group justify='end'>
        <Button color='red' variant='transparent' size='xs' bg='transparent' onClick={() => open(assetDescription)}>
          Report an issue
        </Button>
      </Group>
    </Alert>
  );
}
