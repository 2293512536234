import {
  DATAPREPPING_FAILED,
  IMPORT_FAILED_LIMIT_EXCEEDED,
  IMPORTING_FAILED,
  INCOMPLETE,
  RawModel,
  RawModelUploadStatus,
} from '@/types/rawModel';
import { rawModelErrorStates } from '../actions/utils';
import { AssetRow } from '../components/table/hooks/types';

export const getAssetErrors = (row: AssetRow, isUploadPending: boolean) => {
  const isFailedImport = hasImportError(row, isUploadPending);

  return getErrors(isFailedImport, row.errors, row.uploadStatus);
};

const GENERAL_FAILED_MESSAGE =
  'Import failed. Please remove the file and try again. If the issue persists, check the file for errors.';
const IMPORT_FAILED_LIMIT_EXCEEDED_MESSAGE =
  'Insufficient credits. Please adjust your overdraft limits or subscribe to a higher tier plan.';

const errorMessages: { [key in RawModelUploadStatus]?: string } = {
  [DATAPREPPING_FAILED]: GENERAL_FAILED_MESSAGE,
  [IMPORTING_FAILED]: GENERAL_FAILED_MESSAGE,
  [IMPORT_FAILED_LIMIT_EXCEEDED]: IMPORT_FAILED_LIMIT_EXCEEDED_MESSAGE,
  [INCOMPLETE]: GENERAL_FAILED_MESSAGE,
};

const getErrors = (isFailed?: boolean, errors?: string[], uploadStatus?: RawModelUploadStatus) => {
  if (errors && errors.length > 0) {
    return errors;
  }
  if (isFailed && uploadStatus && uploadStatus in errorMessages) {
    return [errorMessages[uploadStatus]!];
  }
  return [];
};

export const hasImportError = (row: AssetRow, isUploadPending: boolean) => {
  if (isUploadPending && row.uploadStatus === INCOMPLETE) {
    return false;
  }
  return row.uploadStatus && rawModelErrorStates.has(row.uploadStatus);
};
