import { useState } from 'react';
import { useRawAssetUpload } from '@/app/assets/components/upload/hooks/useFileUpload';
import { FileWithPath } from '@mantine/dropzone';
import { ModelImportConfig } from '@/app/assets/components/upload/hooks/usePBROptions';
import { RawModel } from '@/types/rawModel';
import { showErrorNotification, showSuccessNotification } from '@/app/components/shared/notifications';

export const useFiles = (onFilesUploaded: () => void) => {
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [filesPendingUpload, setFilesPendingUpload] = useState<RawModel[]>([]);
  const [isUploadPending, setIsUploadPending] = useState(false);
  const { uploadFiles, uploadProgress } = useRawAssetUpload(files, (assets) =>
    setFilesPendingUpload((s) => [...s, ...assets.filter((a) => !s.some((i) => i.id === a.id))])
  );

  const removeFile = async (index: number) => {
    setFiles((files) => files.filter((_, idx) => idx !== index));
  };

  const removeAllFiles = async () => {
    setFiles([]);
  };

  const onFileDropped = async (droppedFiles: FileWithPath[]) => {
    showSuccessNotification({ title: 'Files added', message: 'New files added to the upload list.' });
    setFiles((s) => [...droppedFiles, ...s]);
  };

  return {
    files,
    filesPendingUpload,
    isUploadPending,
    removeFile,
    removeAllFiles,
    uploadFiles: async (importConfig?: ModelImportConfig) => {
      setIsUploadPending(true);
      try {
        await uploadFiles(importConfig);
        onFilesUploaded();
      } catch (e) {
        if (e instanceof Error && e.message) {
          return showErrorNotification({ message: e.message });
        }
        throw e;
      } finally {
        await removeAllFiles();
        setFilesPendingUpload([]);
        setIsUploadPending(false);
      }
    },
    uploadProgress,
    onFileDropped,
  };
};
