import JSZip from 'jszip';
import { FileWithPath } from '@mantine/dropzone';

const _3dm = '3dm';
const _3ds = '3ds';
const _3dxml = '3dxml';
const _3mf = '3mf';
const asm = 'asm';
const catdrawing = 'catdrawing';
const catpart = 'catpart';
const catproduct = 'catproduct';
const catshape = 'catshape';
const cgr = 'cgr';
const dae = 'dae';
const dlv = 'dlv';
const dwf = 'dwf';
const dwfx = 'dwfx';
const dwg = 'dwg';
const dxf = 'dxf';
const exp = 'exp';
const iam = 'iam';
const ipt = 'ipt';
const jt = 'jt';
const model = 'model';
const neu = 'neu';
const par = 'par';
const prt = 'prt';
const psm = 'psm';
const pwd = 'pwd';
const rfa = 'rfa';
const rvt = 'rvt';
const session = 'session';
const sldasm = 'sldasm';
const sldprt = 'sldprt';
const u3d = 'u3d';
const wrl = 'wrl';
const x_b = 'x_b';
const x_t = 'x_t';
const xas = 'xas';
const xmt = 'xmt';
const xmt_txt = 'xmt_txt';
const xpr = 'xpr';

export const mainTypes = [
  'obj',
  'ply',
  'gltf',
  'glb',
  'fbx',
  'stl',
  'step',
  'stp',
  'iges',
  'igs',
  'usd',
  'usdc',
  'usda',
  'usdz',
  'zip',
  'max',
  'vrm',
  _3dm,
  _3ds,
  _3dxml,
  _3mf,
  asm,
  catdrawing,
  catpart,
  catproduct,
  catshape,
  cgr,
  dae,
  dlv,
  dwf,
  dwfx,
  dwg,
  dxf,
  exp,
  iam,
  ipt,
  jt,
  model,
  neu,
  par,
  prt,
  psm,
  pwd,
  rfa,
  rvt,
  session,
  sldasm,
  sldprt,
  u3d,
  wrl,
  x_b,
  x_t,
  xas,
  xmt,
  xmt_txt,
  xpr,
];

export const cadTypes = ['stp', 'step', 'iges', 'igs'];

export const advancedCadTypes = [
  _3dm,
  _3ds,
  _3dxml,
  _3mf,
  asm,
  catdrawing,
  catpart,
  catproduct,
  catshape,
  cgr,
  dae,
  dlv,
  dwf,
  dwfx,
  dwg,
  dxf,
  exp,
  iam,
  ipt,
  jt,
  model,
  neu,
  par,
  prt,
  psm,
  pwd,
  rfa,
  rvt,
  session,
  sldasm,
  sldprt,
  u3d,
  wrl,
  x_b,
  x_t,
  xas,
  xmt,
  xmt_txt,
  xpr,
];

export function isCad(format: string) {
  return cadTypes.includes(format.toLowerCase()) || advancedCadTypes.includes(format.toLowerCase());
}

export const pbrTypes = ['max'];

export const mainTypeGroups = [
  ['obj', 'mtl', 'mat'],
  ['gltf', 'bin'],
  ['fbx'],
  ['usd'],
  ['usdc'],
  ['usda'],
  ['usdz'],
  ['max'],
  ['ply'],
  ['glb'],
  ['stl'],
  ['step'],
  ['iges'],
  ['stp'],
  ['igs'],
  ['zip'],
  [_3dm],
  [_3ds],
  [_3dxml],
  [_3mf],
  [asm, prt],
  [catdrawing],
  [catproduct, catpart],
  [catshape],
  [cgr],
  [dae],
  [dlv],
  [dwf],
  [dwfx],
  [dwg],
  [dxf],
  [exp],
  [iam, ipt],
  [ipt],
  [jt],
  [model],
  [neu],
  [par],
  [prt],
  [psm],
  [pwd],
  [rfa],
  [rvt],
  [session],
  [sldasm],
  [sldprt],
  [u3d],
  [wrl],
  [x_b],
  [x_t],
  [xas],
  [xmt],
  [xmt_txt],
  [xpr],
];

export const zippedFormats = ['gltf', 'obj', 'ctm', 'usd', 'usda', 'usdc', 'ply'];

const archiveTypes = ['zip', 'rar', '7z', 'tar', 'gz', 'bz2', 'xz', 'lz', 'lzma', 'tgz', 'tbz2', 'txz', 'dmg'];
const images = ['png', 'jpg', 'jpeg', 'webp', 'hdr', 'exr', 'tiff', 'tga', 'psd'];
const misc = ['db', 'json', 'txt', 'rtf', 'docx', 'pdf', 'xml', 'bin', 'mtl', 'mat'];
export const otherTypes = [...images, ...misc];

const hasPrefix = (path: string) => {
  return ['__MACOSX'].find((prefix) => path.startsWith(prefix));
};
const hasSuffix = (path: string) => {
  return ['/', '.DS_Store'].find((suffix) => path.endsWith(suffix));
};

export const listContent = async (zipFile: Blob | File) => {
  const res = await JSZip.loadAsync(zipFile);
  const content = Object.keys(res.files).filter((path) => !hasPrefix(path) && !hasSuffix(path));
  return content;
};

export const getFileMeta = (file: FileWithPath | string) => {
  const fileEnding = (
    (file as FileWithPath).name
      ? getFileExtension(file as FileWithPath)
      : /[.]/.exec(file as string)
        ? /[^.]+$/.exec(file as string)?.[0]
        : undefined
  )?.toLowerCase();

  if (!fileEnding) return;

  const isMainType = mainTypes.includes(fileEnding);
  const isArchive = archiveTypes.includes(fileEnding);

  return {
    fileEnding,
    isMainType,
    isArchive,
    isAuxType: !isMainType && !isArchive,
    isCad: cadTypes.includes(fileEnding),
    isAdvancedCad: advancedCadTypes.includes(fileEnding),
    isPBR: pbrTypes.includes(fileEnding),
    isSupportedArchive: fileEnding === 'zip',
    isJSON: fileEnding === 'json',
  };
};

export const getFilesMeta = (files: FileWithPath[] | string[]) => {
  const metas = files.map((file) => getFileMeta(file)).filter((s) => s);

  const fileEndings = metas.map((meta) => meta!.fileEnding);
  const mainTypeCount = metas.filter((meta) => meta!.isMainType).length;
  const auxTypeCount = metas.filter((meta) => meta!.isAuxType).length;
  const includesCad = metas.filter((meta) => meta!.isCad).length > 0;
  const includesAdvancedCad = metas.filter((meta) => meta!.isAdvancedCad).length > 0;
  const includesPBR = metas.filter((meta) => meta!.isPBR).length > 0;
  const jsonCount = metas.filter((meta) => meta!.isJSON).length;

  return {
    fileEndings,
    mainTypeCount,
    auxTypeCount,
    includesCad,
    includesAdvancedCad,
    includesPBR,
    jsonCount,
  };
};

export function nameWithoutExt(name: string) {
  const parts = name.split('.');
  const partsWithoutEnding = parts.slice(0, parts.length - 1); // remove extension
  return partsWithoutEnding.join('.'); // join again in case of `.` in filename
}

export function getFileExtension(file: Pick<FileWithPath, 'name'>) {
  const parts = file.name.split('.');

  return parts.length ? parts[parts.length - 1] : '';
}
