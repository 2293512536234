import { Text, TextProps } from '@mantine/core';

export function TableHeader({ title, ...rest }: { title: string } & TextProps) {
  return (
    <Text
      fw={600}
      c='dark.2'
      tt='uppercase'
      style={{ whiteSpace: 'nowrap' }}
      ta='left'
      {...rest}
    >
      {title}
    </Text>
  );
}
