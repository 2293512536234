import { AdditionalDownloads } from '@/app/assets/actions/downloads';
import { ApiRapidModel, RapidModel } from '@/types/rapidModel';

export type AssetTag = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  raw_id?: number;
  pivot?: {
    '3dmodel_id': number;
    tag_id: number;
  };
};

type RawAssetDownloads = {
  [fileName: string]: string;
};

type Links = {
  first: string;
  last: string;
  prev: string | null;
  next: string | null;
};

type MetaLink = {
  url: string | null;
  label: string;
  active: boolean;
};

type Meta = {
  current_page: number;
  from: number;
  last_page: number;
  links: MetaLink[];
  path: string;
  per_page: number;
  to: number;
  total: number;
};

export type Converted = {
  name: string;
  human_name: string;
  url: string;
};

export type ApiRawModel = {
  id: number;
  uuid: string;
  name: string;
  external: boolean;
  upload_status: RawModelUploadStatus;
  import_status: string | null;
  progress: string | null;
  error_message: string | null;
  size: number;
  created_at: string;
  scale_factor: string;
  has_errors: boolean;
  format: string;
  tags?: AssetTag[];
  metadata?: ApiMetadata;
  downloads?: RawAssetDownloads | null;
  converted_fbx?: Converted;
  converted_glb?: Converted;
  converted_gltf?: Converted;
  converted_max?: Converted;
  converted_usdz?: Converted;
  rapid_models?: ApiRapidModel[];
};

export type RawModel = Omit<
  ApiRawModel,
  'uuid' | 'external' | 'import_status' | 'progress' | 'metadata' | 'error_message' | 'scale_factor' | 'has_errors'
> & {
  rpdInfo: RpdInfo;
  rapidModels: RapidModel[];
  assetType: 'raw';
  scale_factor: number;
  warnings: string[];
  errors: string[];
  additionalDownloads?: AdditionalDownloads;
  metadata?: Metadata;
};

export type GetRawModelResponse = {
  data: ApiRawModel[];
  links: Links;
  meta: Meta;
};

export type RawModelPreview = {
  file: string;
  model_url: string;
  config: string;
  size: number;
  token: string;
};

export type RawModelPbr = {
  qc?: Record<string, string>;
  materials: Record<string, string>;
} & Record<string, string>;

export type UpdateRawModel = Partial<Omit<RawModel, 'tags'> & { tags: string[] }>;

export const COMPLETE = 'complete' as const;
export const WAITING = 'waiting' as const;
export const UNZIPPING = 'unzipping' as const;
export const ANALYSING = 'analysing' as const;
export const DATAPREPPING = 'dataprepping' as const;
export const DATAPREPPING_FAILED = 'dataprepping failed' as const;
export const RAPID_QC = 'RapidQC' as const;
export const IMPORTING_FAILED = 'Importing Failed' as const;
export const IMPORT_FAILED_LIMIT_EXCEEDED = 'Importing Failed Import Limit Exceeded' as const;
export const INCOMPLETE = 'incomplete' as const;
export const CLIENT_UPLOADING = 'client uploading' as const;
export const JUST_UPLOADED = 'just uploaded' as const;

export type RawModelUploadStatus =
  | typeof COMPLETE
  | typeof WAITING
  | typeof UNZIPPING
  | typeof ANALYSING
  | typeof DATAPREPPING
  | typeof DATAPREPPING_FAILED
  | typeof RAPID_QC
  | typeof IMPORTING_FAILED
  | typeof IMPORT_FAILED_LIMIT_EXCEEDED
  | typeof INCOMPLETE
  | typeof CLIENT_UPLOADING //client status
  | typeof JUST_UPLOADED; //client status

export type ApiMetadata = {
  id: number;
  created_at: string;
  updated_at: string;
  data: string;
};

export type Metadata = {
  id: number;
  createdAt: string;
  updatedAt: string;
  data: Record<string, string | Record<string, string>[]>;
};

export interface RpdInfo {
  readonly polygons: number | null;
  readonly bbMin?: number[];
  readonly bbMax?: number[];
  readonly bbCenter?: number[];
  readonly bbSize?: number[];
  readonly area3D?: number;
  readonly numVertices?: number;
  readonly numVerticesSingleIndexed?: number;
  readonly numTriangles?: number;
  readonly numQuads?: number;
  readonly numMeshes?: number;
  readonly numSubMeshes?: number;
  readonly numDrawCalls?: number;
  readonly numMaterials?: number;
  readonly numNodes?: number;
  readonly numLights?: number;
  readonly hasRigidAnimations?: boolean;
  readonly numRigidAnimations?: number;
  readonly hasSkinnedAnimations?: boolean;
  readonly hasMorphTargets?: boolean;
  readonly animations?: any[];
  readonly extensionsUsed?: any[];
  readonly extensionsUnsupported?: any[];
  readonly numTexels?: number;
  readonly hasUVs?: boolean;
  readonly hasVertexColors?: boolean;
  readonly hasVertexNormals?: boolean;
  readonly hasVertexTangents?: boolean;
  readonly hasVertexBitangents?: boolean;
  readonly numOpaqueMaterials?: number;
  readonly numAlphaBlendMaterials?: number;
  readonly numAlphaMaskMaterials?: number;
  readonly hasBaseColorMap?: boolean;
  readonly hasMetallicMap?: boolean;
  readonly hasRoughnessMap?: boolean;
  readonly hasEmissiveMap?: boolean;
  readonly hasTSNormalMap?: boolean;
  readonly hasOSNormalMap?: boolean;
  readonly hasOcclusionMap?: boolean;
  readonly hasDisplacmentMap?: boolean;
  readonly hasClearcoatMap?: boolean;
  readonly hasClearcoatColorMap?: boolean;
  readonly hasClearcoatRoughnessMap?: boolean;
  readonly hasClearcoatNormalMap?: boolean;
  readonly hasTransmissionMap?: boolean;
  readonly hasSheenColorMap?: boolean;
  readonly hasSheenRoughnessMap?: boolean;
  readonly hasSpecularColorMap?: boolean;
  readonly hasSpecularValueMap?: boolean;
  readonly hasThicknessMap?: boolean;
  readonly hasIridescenceMap?: boolean;
  readonly hasIridescenceThicknessMap?: boolean;
  readonly numBaseColorMaps?: number;
  readonly numMetallicMaps?: number;
  readonly numRoughnessMaps?: number;
  readonly numEmissiveMaps?: number;
  readonly numTSNormalMaps?: number;
  readonly numOSNormalMaps?: number;
  readonly numOcclusionMaps?: number;
  readonly numDisplacmentMaps?: number;
  readonly numClearcoatMaps?: number;
  readonly numClearcoatColorMaps?: number;
  readonly numClearcoatRoughnessMaps?: number;
  readonly numClearcoatNormalMaps?: number;
  readonly numTransmissionMaps?: number;
  readonly numSheenColorMaps?: number;
  readonly numSheenRoughnessMaps?: number;
  readonly numSpecularColorMaps?: number;
  readonly numSpecularValueMaps?: number;
  readonly numThicknessMaps?: number;
  readonly numIridescenceMaps?: number;
  readonly numIridescenceThicknessMaps?: number;
  readonly maxResBaseColorMapUV0?: number;
  readonly maxResMetallicMapUV0?: number;
  readonly maxResRoughnessMapUV0?: number;
  readonly maxResTSNormalMapUV0?: number;
  readonly maxResOcclusionMapUV0?: number;
  readonly avgAngleDistortion?: number;
  readonly maxAngleDistortion?: number;
  readonly displacementReferenceMin?: number;
  readonly displacementReferenceMax?: number;
}
