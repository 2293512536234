import React, {
  createContext,
  useState,
  useContext,
  PropsWithChildren,
} from 'react';
import { AssetRowDescriptor } from '../components/table/hooks/useAssetsTable';

type RowSelectionState = {
  [key: string]: boolean;
};

const RowSelectionContext = createContext<{
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  getSelectedRawAssetsIds: () => number[];
  clearRowSelection: () => void;
}>({
  rowSelection: {},
  setRowSelection: () => {},
  getSelectedRawAssetsIds: () => [],
  clearRowSelection: () => {},
});

const useRowSelection = () => useContext(RowSelectionContext);

function RowSelectionProvider({ children }: PropsWithChildren) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const getSelectedRawAssetsIds = () => {
    return Object.keys(rowSelection)
      .map((key) => {
        const asset = JSON.parse(key) as AssetRowDescriptor;
        return asset;
      })
      .filter((asset) => asset.type === 'raw')
      .map((asset) => asset.id);
  };

  const clearRowSelection = () => {
    setRowSelection({});
  };

  return (
    <RowSelectionContext.Provider
      value={{
        rowSelection,
        setRowSelection,
        getSelectedRawAssetsIds,
        clearRowSelection,
      }}
    >
      {children}
    </RowSelectionContext.Provider>
  );
}

export { RowSelectionProvider, useRowSelection };
