import { cloudApiClient } from '@/services/clientSideCloudApiService';

export interface AdditionalDownloads {
  formats: string[];
  downloadFiles: { name: string; url: string }[];
}

export const getCombinedDownloads = async (
  id: number,
  onlyFromConvertedFilesEndpoint: boolean
): Promise<AdditionalDownloads> => {
  const { data: downloads } = onlyFromConvertedFilesEndpoint
    ? { data: {} }
    : await cloudApiClient.get(`rawmodel/${id}/downloads`, { endpointWithImpersonation: 'GET' });

  const { data: convertedFiles } = await cloudApiClient.get(`rawmodel/${id}/downloads/converted`, {
    endpointWithImpersonation: 'GET',
  });

  const json = { ...downloads, ...convertedFiles };

  const files = Object.keys(json).map((nameWithFormat) => {
    return {
      format: (() => {
        const parts = nameWithFormat.split('.');
        if (parts.length >= 2 && parts[parts.length - 1] === 'zip') {
          // For .x.zip files, return x
          return parts[parts.length - 2];
        } else {
          // For other files, return the last extension without the dot
          return parts[parts.length - 1];
        }
      })(),
      downloadFile: { name: nameWithFormat, url: json[nameWithFormat] },
    };
  });

  return {
    formats: files.map((file) => file.format),
    downloadFiles: files.map((file) => file.downloadFile),
  };
};

export const getDownloadUrlsForRawModel = async (id: number): Promise<{ url: string; name: string }[]> => {
  const { data: json } = await cloudApiClient.get<Record<string, string>>(`rawmodel/${id}/downloads`, {
    endpointWithImpersonation: 'GET',
  });

  return Object.entries(json).map(([name, url]) => ({ name, url }));
};
