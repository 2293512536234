import DeleteIcon from '@/icons/trash.svg';
import { Menu } from '@mantine/core';

interface DeleteItemProps {
  onDelete: () => void;
  isAvailable: boolean;
}

const DeleteItem: React.FC<DeleteItemProps> = ({ onDelete, isAvailable }) => {
  if (!isAvailable) {
    return null;
  }

  return (
    <Menu.Item
      leftSection={<DeleteIcon style={{ width: 14, height: 14 }} />}
      onClick={onDelete}
    >
      Delete
    </Menu.Item>
  );
};

export default DeleteItem;
