import { formatDistanceToNow, parseISO } from 'date-fns';
import dayjs from 'dayjs';

export const getTimePassedText = (dateTime: string) =>
  formatDistanceToNow(parseISO(dateTime), {
    addSuffix: true,
  })
    .replace('about', '')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' days', 'd')
    .replace(' day', 'd')
    .trim();

export const getDateTimeStringInUsersTimezone = (dateTime: string) => {
  return parseISO(dateTime).toLocaleString();
};

export const displayDate = (date: Date | string | null) => (date ? dayjs(date).format('DD.MM.YYYY') : '-');
