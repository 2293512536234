import { Text } from '@mantine/core';
import { FormatBadge } from '../../atoms/FormatBadge';

export const AvailableFormatToConvert = ({
  format,
  isSelected,
  isPending,
  onClick,
}: {
  format: string;
  isSelected: boolean;
  isPending: boolean;
  onClick: () => void;
}) => {
  return (
    <FormatBadge
      style={{ userSelect: 'none' }}
      onClick={onClick}
      color={isSelected || isPending ? 'teal' : 'gray.2'}
      className={isPending ? 'animate-pulse' : ''}
      miw='8ch'
    >
      <Text>.{format}</Text>
    </FormatBadge>
  );
};
