import { Group } from '@mantine/core';
import { AssetRow } from '@/app/assets/components/table/hooks/types';
import { CreatedAtInfoBadge } from '@/app/components/asset/CreatedAtInfoBadge';

export function UploadedAtColumn({
  uploadedAt,
}: {
  uploadedAt: AssetRow['createdAt'];
}) {
  if (!uploadedAt) {
    return null;
  }

  return (
    <Group justify='center'>
      <CreatedAtInfoBadge createdAt={uploadedAt} />
    </Group>
  );
}
