'use client';

import { createContext, PropsWithChildren, useContext, useState } from 'react';

type ShowNewPresetsContextType = {
  setShowNewPresets: (value: boolean) => void;
  showNewPresets: boolean;
};

const ShowNewPresetsContext = createContext<ShowNewPresetsContextType>({
  setShowNewPresets: () => {},
  showNewPresets: false,
});

export const ShowNewPresetsProvider = ({ children }: PropsWithChildren) => {
  const [showNewPresets, setShowNewPresets] = useState(false);

  const handleShowNewPresets = (value: boolean) => {
    setShowNewPresets(value);
  };

  return (
    <ShowNewPresetsContext.Provider value={{ setShowNewPresets: handleShowNewPresets, showNewPresets }}>
      {children}
    </ShowNewPresetsContext.Provider>
  );
};

export const useShowNewPresets = () => {
  const context = useContext(ShowNewPresetsContext);

  if (!context) {
    throw new Error('useShowNewPresets must be used within ShowNewPresetsProvider');
  }

  return context;
};
