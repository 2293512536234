import { Box, Checkbox, Group, GroupProps } from '@mantine/core';
import { Row } from '@tanstack/table-core';
import ChevronIcon from '@/icons/chevron.svg';
import { expandAllChildren } from '@/utils/tableRow';
import { OnboardingFlows } from '../../../../contexts/OnboardingContext/constants';

export function RowActionsColumn<T>({ row, ...props }: { row: Row<T> } & GroupProps) {
  const onChange = () => {
    const value = !row.getIsSelected();
    row.toggleSelected(value);
  };

  const isExpandable = row.getCanExpand();

  if (row.depth === 1) {
    return (
      <Group justify='flex-end' {...props}>
        {isExpandable ? (
          <ChevronIcon
            {...{
              onClick: () => expandAllChildren(row),
              style: {
                marginRight: 2,
                cursor: 'pointer',
                transform: `rotate(${row.getIsExpanded() ? 180 : 0}deg)`,
                flexShrink: 0,
                color: 'var(--mantine-color-dark-9)',
              },
            }}
          />
        ) : null}
      </Group>
    );
  }

  if (row.depth === 2) {
    return null;
  }

  return (
    <Group justify='flex-end' {...props} gap='sm'>
      {isExpandable ? (
        <ChevronIcon
          id={`${OnboardingFlows.EXPAND_ASSET}-${(row.original as { basicInfo?: { id: number } } | undefined)?.basicInfo?.id}`}
          {...{
            onClick: () => expandAllChildren(row),
            style: {
              cursor: 'pointer',
              transform: `rotate(${row.getIsExpanded() ? 180 : 0}deg)`,
              flexShrink: 0,
              color: 'var(--mantine-color-dark-9)',
            },
          }}
        />
      ) : null}
      <Box //This Box increases checkboxes hitbox area for source models.
        onClick={(e) => {
          e.stopPropagation();
          onChange();
        }}
        px={10}
        mx={-10}
        py={20}
        my={-20}
        style={{ cursor: 'default' }}
      >
        <Checkbox
          {...{
            onClick: (e) => e.stopPropagation(),
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange,
          }}
          id={`${OnboardingFlows.RUN_PRESET}-0`}
        />
      </Box>
    </Group>
  );
}
