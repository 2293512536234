import DownloadIcon from '@/icons/download.svg';
import { Menu } from '@mantine/core';

interface DownloadItemProps {
  onDownload: () => void;
  isAvailable: boolean;
}

const DownloadItem: React.FC<DownloadItemProps> = ({ onDownload, isAvailable }) => {
  if (!isAvailable) {
    return null;
  }

  return (
    <Menu.Item leftSection={<DownloadIcon style={{ width: 14, height: 14 }} />} onClick={onDownload}>
      Download
    </Menu.Item>
  );
};

export default DownloadItem;
