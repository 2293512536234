import { ActionIcon, HoverCard, TextInput, Title, Text, Group } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { updateRawModel } from '../../actions/rawModel';
import { showErrorNotification, showSuccessNotification } from '@/app/components/shared/notifications';
import { MAIN_ASSET_QUERY_KEY } from '../../hooks/useAssetsQuery';
import { useOptimisticAssetDetails } from '../hooks/useOptimisticAssetDetails';
import { truncateStringInMiddle } from '@/utils/truncateString';
import EditIcon from '@/icons/edit.svg';
import { renameRapidModel } from '../../actions/rapidModel';

export function AssetName({
  name,
  id,
  isOutput,
  disabled,
  inTableRow,
}: {
  name: string;
  id: number;
  isOutput: boolean;
  disabled?: boolean;
  inTableRow?: boolean;
}) {
  const { onNameChange } = useOptimisticAssetDetails();
  const [isInEdit, setIsInEdit] = useState(false);
  const [newName, setNewName] = useState(name);

  const queryClient = useQueryClient();

  const { mutate, isError } = useMutation({
    mutationFn: async (name: string) => {
      if (isOutput) {
        return await renameRapidModel(id, name);
      }

      const updated = updateRawModel(id, { name });
      onNameChange(id, newName);

      return updated;
    },
    onSuccess: () => {
      setIsInEdit(false);
      showSuccessNotification({
        title: '✅ Asset name updated',
        message: '✨ Asset name was successfully updated ✨',
      });

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(MAIN_ASSET_QUERY_KEY),
      });
    },
    onError: (error) => {
      showErrorNotification({
        title: '❌ Failed to update asset name',
        message: `❗️ ${error.message}`,
      });
    },
  });

  const ref = useClickOutside(() => {
    const newNameTrimmed = newName.trim();
    if (newNameTrimmed === name) {
      return setIsInEdit(false);
    }
    mutate(newNameTrimmed);
  });

  useEffect(() => {
    setNewName(name);
  }, [name, setNewName]);

  if (isInEdit) {
    return (
      <TextInput
        onClick={(e) => e.stopPropagation()}
        ref={ref}
        defaultValue={name}
        onChange={(e) => setNewName(e.target.value)}
        error={isError ? "Can't update asset name" : null}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            mutate(newName);
          }
        }}
      />
    );
  }

  if (disabled) {
    if (inTableRow) {
      return (
        <Text c='dark.9' fw={600} maw={310} truncate='end'>
          {truncateStringInMiddle(name, {
            breakPoint: 30,
            maxLength: 30,
          })}
        </Text>
      );
    }
    return <Title order={5}>{truncateStringInMiddle(newName, { breakPoint: 40, maxLength: 40 })}</Title>;
  }

  return (
    <HoverCard offset={0} position='right'>
      <HoverCard.Target>
        {inTableRow ? (
          <Text c='dark.9' fw={600} maw={310} truncate='end' onDoubleClick={() => setIsInEdit(true)}>
            {truncateStringInMiddle(name, {
              breakPoint: 30,
              maxLength: 30,
            })}
          </Text>
        ) : (
          <Title onDoubleClick={() => setIsInEdit(true)} order={5}>
            {truncateStringInMiddle(newName, { breakPoint: 40, maxLength: 40 })}
          </Title>
        )}
      </HoverCard.Target>
      <HoverCard.Dropdown onClick={(e) => e.stopPropagation()} style={{ padding: 0, border: 'none' }}>
        <ActionIcon style={{ backgroundColor: 'transparent' }} onClick={() => setIsInEdit(true)}>
          <EditIcon />
        </ActionIcon>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
