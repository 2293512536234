import { cloudApiClient } from '@/services/clientSideCloudApiService';

type PublishConfig = {
  model_id: number;
  config: unknown;
  name: string;
};

export type PublishResponse = {
  status: string;
  message: string;
  rapidmodel_id: number;
  embed_id: number;
  embed_url: string;
};

export const publishOneClickEmbed = async (body: PublishConfig) => {
  const res = await cloudApiClient.put('embeds/upsert-one-click', body);

  return res.data as Promise<PublishResponse>;
};
