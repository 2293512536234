import { API_TOKENS_QUERY_KEY } from '@/app/api_tokens/hooks/useApiTokensQuery';
import { MAIN_ASSET_QUERY_KEY } from '@/app/assets/hooks/useAssetsQuery';
import { MAIN_PUBLISHED_QUERY_KEY } from '@/app/published/hooks/usePublishedQuery';
import { USER_OR_IMPERSONATED_USER_QUERY_KEY } from '@/app/settings/hooks/useUserOrImpersonatedUserQuery';
import { WORKFLOWS_QUERY_MAIN_KEY } from '@/app/workflows/components/table/query/useWorkflowsQuery';
import { USER_QUERY_KEY } from '@/shared/hooks/useUserQuery';
import { IMPERSONATION_MAIN_QUERY_KEY } from '@/shared/useImpersonationQuery';
import { QueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';

type ImpersonationReturnType =
  | {
      email: string;
      isImpersonating: true;
    }
  | {
      email: undefined;
      isImpersonating: false;
    };

export const getImpersonation = (): ImpersonationReturnType => {
  const impersonationEmail = Cookies.get(IMPERSONATION_COOKIE_NAME);

  if (impersonationEmail) {
    return {
      email: impersonationEmail,
      isImpersonating: true,
    };
  }

  return {
    email: undefined,
    isImpersonating: false,
  };
};

export const IMPERSONATION_COOKIE_NAME = 'impersonateEmail';

export const setImpersonationCookie = (email: string) => {
  Cookies.set(IMPERSONATION_COOKIE_NAME, email);
};

export const removeImpersonationCookie = () => {
  Cookies.remove(IMPERSONATION_COOKIE_NAME);
};

export const invalidateImpersonationQueries = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    predicate: (query) =>
      [
        MAIN_ASSET_QUERY_KEY,
        WORKFLOWS_QUERY_MAIN_KEY,
        MAIN_PUBLISHED_QUERY_KEY,
        IMPERSONATION_MAIN_QUERY_KEY,
        USER_QUERY_KEY,
        USER_OR_IMPERSONATED_USER_QUERY_KEY,
        API_TOKENS_QUERY_KEY,
      ].some((key) => query.queryKey.includes(key)),
  });
};
