import { ModelImportConfig } from '@/app/assets/components/upload/hooks/usePBROptions';
import { cloudApiClient } from '@/services/clientSideCloudApiService';

type CreateRawModelArgs = {
  model_name: string;
  model_size: number;
  model_import_config?: ModelImportConfig;
};

type CreateRawModelResponse = {
  id: number;
  uuid: string;
};

type StartUploadRawModelArgs = {
  filename: string;
  uuid: string;
};

type StartUploadRawModelResponse = {
  key: string;
  upload_id: string;
};

type GetPartUploadUrlArgs = {
  content_length: number;
  key: string;
  part_number: number;
  upload_id: string;
};

type GetPartUploadUrlResponse = {
  url: string;
};

type CompleteUploadArgs = {
  key: string;
  upload_id: string;
};

type CompleteUploadResponse = {
  url: string;
};

type ErrorResponse = {
  message: string;
};

export const createRawModel = async function (args: CreateRawModelArgs) {
  const response = await cloudApiClient.post<CreateRawModelResponse>('rawmodel/create', args);
  return response.data;
};

export const startUpload = async function (args: StartUploadRawModelArgs) {
  const response = await cloudApiClient.post<StartUploadRawModelResponse>('rawmodel/upload/start', args);
  return response.data;
};

export const getPartUploadUrl = async function getPartUploadUrl(args: GetPartUploadUrlArgs) {
  const response = await cloudApiClient.post<GetPartUploadUrlResponse>('rawmodel/upload/part', args);
  return response.data;
};

export const completeUpload = async function (args: CompleteUploadArgs) {
  const response = await cloudApiClient.post<CompleteUploadResponse>('rawmodel/upload/complete', args);
  return response.data;
};

export const finishAssetUpload = async function ({ rawModelId }: { rawModelId: number }) {
  await cloudApiClient.get(`rawmodel/${rawModelId}/api-upload/complete`);
};
