import { useQuery } from '@tanstack/react-query';
import { searchForModels } from '@/app/components/supportTicket/actions/searchForModels';
import { useDebounce } from 'use-debounce';

export const getSearchModelsQueryKey = (searchTerm: string) => ['searchModels', searchTerm];

export function useSearchForModelsQuery(searchTerm: string, debounce = 500) {
  const [debouncedSearchQuery] = useDebounce(searchTerm, debounce);
  const key = getSearchModelsQueryKey(debouncedSearchQuery);

  return useQuery({
    queryKey: key,
    queryFn: async () => {
      if (debouncedSearchQuery.length < 3) {
        return [];
      }
      return searchForModels(debouncedSearchQuery);
    },
    throwOnError: false,
  });
}
