import { IconRefresh } from '@tabler/icons-react';
import { Menu } from '@mantine/core';

interface ConvertItemProps {
  onConvert: () => void;
  isAvailable: boolean;
}

const ConvertItem: React.FC<ConvertItemProps> = ({ onConvert, isAvailable }) => {
  if (!isAvailable) {
    return null;
  }

  return (
    <Menu.Item leftSection={<IconRefresh style={{ width: 14, height: 14 }} />} onClick={onConvert}>
      Convert
    </Menu.Item>
  );
};

export default ConvertItem;
