import { RawModelUploadStatus } from '@/types/rawModel';
import { rawModelFinishedStates, rawModelSuccessStates } from '@/app/assets/actions/utils';
import { ActionsDropdownWrapper } from '@/app/assets/components/table/columns/assetActionsColumn/ActionsDropdown';
import DownloadItem from './items/DownloadItem';
import DeleteItem from './items/DeleteItem';
import { Button, Group, Menu } from '@mantine/core';
import { IconBolt } from '@tabler/icons-react';
import { AssetRow } from '../../hooks/types';
import { useDisclosure } from '@mantine/hooks';
import { AddConverterOutputs } from '../../AddConvertedFormatsModal/AddConverterFormatsModal';
import { useCallback, useState } from 'react';
import ConvertItem from './items/ConvertItem';

export type AssetActionsColumnProps = {
  onDownload: () => void;
  onDelete: () => void;
  onProcess: () => void;
  status?: RawModelUploadStatus;
  asset: AssetRow;
};

const rawModelStatusActionMap: Record<'delete' | 'download' | 'process' | 'convert', Set<RawModelUploadStatus>> = {
  process: rawModelSuccessStates,
  delete: rawModelFinishedStates,
  download: rawModelSuccessStates,
  convert: rawModelSuccessStates,
};

export function RawAssetActionsColumn({ onDownload, onDelete, onProcess, status, asset }: AssetActionsColumnProps) {
  const [isConvertFormatModalOpened, { open, close }] = useDisclosure();
  const [isConverterFormatModalOpenedDelayed, setConverterFormatModalOpenedDelayed] =
    useState(isConvertFormatModalOpened);

  //I don't like this solution too but otherwise the animations won't work.
  const openDelayed = useCallback(() => {
    open();
    setTimeout(() => setConverterFormatModalOpenedDelayed(true), 0);
  }, [open]);

  const closeDelayed = useCallback(() => {
    setConverterFormatModalOpenedDelayed(false);
    setTimeout(close, 500);
  }, [close]);

  if (!status || !rawModelFinishedStates.has(status)) {
    return null;
  }

  const isProcessorAvailable = rawModelStatusActionMap.process.has(status);
  const isDeleteAvailable = rawModelStatusActionMap.delete.has(status);
  const isDownloadAvailable = rawModelStatusActionMap.download.has(status);
  const isFormatConverterAvailable = rawModelStatusActionMap.convert.has(status);

  return (
    <>
      <Group wrap='nowrap' gap={6}>
        {isProcessorAvailable && (
          <Button bg='teal.0' radius={36} w={40} p={0} style={{ border: 'none' }} onClick={onProcess}>
            <IconBolt width={20} stroke={1} />
          </Button>
        )}
        <ActionsDropdownWrapper>
          <DownloadItem onDownload={onDownload} isAvailable={isDownloadAvailable} />
          <DeleteItem onDelete={onDelete} isAvailable={isDeleteAvailable} />
          <ConvertItem onConvert={openDelayed} isAvailable={isFormatConverterAvailable} />
        </ActionsDropdownWrapper>
      </Group>
      {isConvertFormatModalOpened && (
        <AddConverterOutputs
          key={asset.basicInfo.rowType + asset.basicInfo.id}
          asset={asset}
          close={closeDelayed}
          opened={isConverterFormatModalOpenedDelayed}
        />
      )}
    </>
  );
}
