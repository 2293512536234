const createQueryString = (values: Record<string, string | number | boolean | undefined>) => {
  return Object.entries(values)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

export const getQueryString = (values: Record<string, string | number | boolean | undefined>) => {
  const queryString = createQueryString(values);

  if (!queryString) {
    return '';
  }
  return `?${queryString}`;
};

export const appendToQueryString = (
  queryString: string,
  values: Record<string, string | number | boolean | undefined>
) => {
  if (!queryString) {
    return getQueryString(values);
  }
  const queryStringToAppend = createQueryString(values);

  if (!queryStringToAppend) {
    return queryString;
  }

  return `${queryString}&${queryStringToAppend}`;
};
