import { AssetRow } from '@/app/assets/components/table/hooks/types';
import { deleteRapidModels } from '@/app/assets/actions/rapidModel';
import { deleteRawModels } from '@/app/assets/actions/rawModel';

export async function deleteAsset(asset: AssetRow) {
  if (asset.basicInfo.rowType === 'output') {
    return deleteRapidModels([asset.basicInfo.id]);
  }

  if (asset.basicInfo.rowType === 'raw') {
    await deleteRawModels([asset.basicInfo.id]);
  }
}

export async function downloadFile(asset: {
  downloadUrl: string;
  id?: number;
}) {
  const link = document.createElement('a');
  link.id = asset.downloadUrl;
  link.download = '';
  link.href = asset.downloadUrl;
  link.click();
}
