import { ApiWorkflow, ApiWorkflowJson, InvalidWorkflow, Workflow } from './interfaces';
import { WorkflowTab } from '../utils/navigation';
import { getGeneralWorkflows, getApplicationWorkflows, getDeviceWorkflows } from '../utils/workflowTypes';
import { getBlankWorkflow, getBlankWorkflowV7 } from './constants/blankWorkflow';
import { asFactoryWorkflow, mapApiWorkflowToWorkflow, mapAsCustomerWorkflow } from './utils/mappers';
import { byNameAndDescription } from './utils/sorters';
import { byAllWorkflowsOrder, byApplicationOrder, byDeviceOrder, byGeneralOrder } from './utils/sorting';
import { cloudApiClient } from '@/services/clientSideCloudApiService';
import { isRpdx7 } from '../utils/isRpdx7';
import { showErrorNotification } from '@/app/components/shared/notifications';
import { getImpersonation } from '@/utils/impersonation';

const getFactoryWorkflows = async function (): Promise<Workflow[]> {
  let { data } = await cloudApiClient.get<ApiWorkflow[]>('preset/factory');

  // Get only Mid Res Workflows
  data = data.filter((workflow) => isRpdx7(workflow.rpdx_version) || workflow.name.endsWith('Mid Res'));

  const { isImpersonating } = getImpersonation();

  const validWorkflows = getValidWorkflows(data);
  return validWorkflows.map((workflow) => asFactoryWorkflow(workflow, isImpersonating));
};

const getCustomWorkflows = async function (): Promise<Workflow[]> {
  const { isImpersonating } = getImpersonation();

  const { data } = await cloudApiClient.get<ApiWorkflow[]>('preset', { endpointWithImpersonation: 'GET' });

  const validWorkflows = getValidWorkflows(data);
  return validWorkflows.map((workflow) => mapAsCustomerWorkflow(workflow, isImpersonating));
};

const getWorkflowsBasedOnTab = async (tab: WorkflowTab) => {
  if (tab === WorkflowTab.ALL) {
    const [factory, workflows] = await Promise.all([getFactoryWorkflows(), getCustomWorkflows()]);

    const sortedFactory = factory.sort(byAllWorkflowsOrder);
    const reversedCustom = workflows.reverse();
    return [...reversedCustom, ...sortedFactory];
  }

  if (tab === WorkflowTab.CUSTOM) {
    const customWorkflows = await getCustomWorkflows();
    return customWorkflows.reverse();
  }

  const workflows = await getFactoryWorkflows();

  if (tab === WorkflowTab.GENERAL) {
    return workflows.filter(getGeneralWorkflows).sort(byGeneralOrder);
  }
  if (tab === WorkflowTab.APPLICATION) {
    return workflows.filter(getApplicationWorkflows).sort(byApplicationOrder);
  }

  return workflows.filter(getDeviceWorkflows).sort(byDeviceOrder);
};

export const getWorkflows = async function (tab: WorkflowTab = WorkflowTab.ALL, search: string | null = null) {
  const workflows = await getWorkflowsBasedOnTab(tab);

  if (search) {
    const filtered = workflows.filter(byNameAndDescription(search));
    return filtered;
  }

  return workflows;
};

export const updateWorkflow = async (workflow: Workflow) => {
  return await cloudApiClient.patch(`preset/update`, workflow);
};

export const deleteWorkflows = async (ids: number[]) => {
  try {
    const requests = ids.map((id) => cloudApiClient.delete(`preset/${id}`));
    await Promise.all(requests);
    return 200;
  } catch (_) {
    return 500;
  }
};

export const createWorkflow = async (workflow: ApiWorkflowJson) => {
  delete workflow.config.workflowSettings;
  const result = await cloudApiClient.post('preset/create', workflow);
  return result.data;
};

export const duplicateWorkflows = async (workflows: Workflow[]) => {
  try {
    const requests = workflows.map((workflow) => createWorkflow({ ...workflow, name: `${workflow.name} (copy)` }));
    await Promise.all(requests);
    return 200;
  } catch (_) {
    return 500;
  }
};

export const createBlankWorkflow = async (features: any) => {
  return createWorkflow(getBlankWorkflow(features));
};

export const createBlankWorkflowV7 = async () => {
  return createWorkflow(getBlankWorkflowV7());
};

const getValidWorkflows = function (workflows: ApiWorkflow[]) {
  const mappedWorkflows = workflows.map((workflow) => mapApiWorkflowToWorkflow(workflow));
  const invalidWorkflows = mappedWorkflows.filter((workflow) => 'invalid' in workflow);

  invalidWorkflows.forEach((workflow) =>
    showErrorNotification({
      message: `Preset ${workflow.id}: ${workflow.name} failed to load! Please contact the customer service!`,
    })
  );

  return mappedWorkflows.filter((workflow): workflow is Omit<Workflow, 'clientMetadata'> => !('invalid' in workflow));
};
