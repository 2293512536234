import { Box, HoverCard, useMantineTheme } from '@mantine/core';
import WarningIcon from '@/icons/warning.svg';
import { ErrorList } from '@/app/assets/components/table/columns/basicInfoColumn/InfosList';
import { ModelDescriptor } from '@/app/components/supportTicket/types';

interface AssetErrorsTooltipProps {
  errors: string[];
  assetDescription?: ModelDescriptor;
}

export function AssetErrorsTooltip({ errors, assetDescription }: AssetErrorsTooltipProps) {
  const theme = useMantineTheme();

  if (!errors.length) {
    return null;
  }

  return (
    <HoverCard shadow='md'>
      <HoverCard.Target>
        <Box style={{ zIndex: 190 }}>
          <WarningIcon color={theme.colors.red[6]} />
        </Box>
      </HoverCard.Target>
      <HoverCard.Dropdown p='0'>
        <ErrorList errors={errors} assetDescription={assetDescription} />
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
