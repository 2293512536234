import { Badge, BadgeProps, PolymorphicComponentProps } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { PropsWithChildren } from 'react';

type FormatBadgeProps = PropsWithChildren & PolymorphicComponentProps<'div', BadgeProps>;

export const FormatBadge = ({ children, ...props }: FormatBadgeProps) => {
  const { hovered, ref } = useHover();

  return (
    <Badge
      ref={ref}
      color={hovered ? 'teal.2' : 'gray.2'}
      px='4'
      py='sm'
      c='black'
      radius='md'
      tt='lowercase'
      {...props}
      style={props.onClick ? { cursor: 'pointer', ...props.style } : props.style}
    >
      {children}
    </Badge>
  );
};
