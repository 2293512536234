import { useFileDownload } from '@/app/components/shared/fileDownload';
import { getDownloadUrlsForRawModel } from '@/app/assets/actions/downloads';
import { showErrorNotification } from '@/app/components/shared/notifications';
import { useCallback } from 'react';

export const useSingleRawAssetDownload = () => {
  const { downloadZip } = useFileDownload();

  const onDownload = useCallback(
    async (assetId: number, downloadName?: string) => {
      const filesToDownload = await getDownloadUrlsForRawModel(assetId);

      if (filesToDownload.length === 0) {
        showErrorNotification({
          title: 'Download files',
          message: 'No files to download',
        });
        return;
      }

      if (filesToDownload.length === 1) {
        return downloadZip([{ url: filesToDownload[0].url, name: filesToDownload[0].name }], downloadName);
      }

      return downloadZip(filesToDownload, downloadName);
    },
    [downloadZip]
  );
  return { onDownload };
};
