import { Text } from '@mantine/core';
import { AssetRow } from '../../hooks/types';
import { showErrorNotification } from '@/app/components/shared/notifications';
import { getBlob } from '@/app/components/shared/fileDownload/utils';
import { useState } from 'react';
import { FormatBadge } from '../../atoms/FormatBadge';

export const ConvertedFormat = ({ format, asset }: { format: string; asset: AssetRow }) => {
  const [isDownloadPending, setIsDownloadPending] = useState(false);
  const [progress, setProgress] = useState(0);

  return (
    <FormatBadge
      miw='8ch'
      style={{ userSelect: 'none' }}
      onClick={async () => {
        setIsDownloadPending(true);
        await download(format, asset, setProgress);
        setIsDownloadPending(false);
        setProgress(0);
      }}
    >
      <Text>{isDownloadPending ? <>{progress}%</> : <>.{format}</>}</Text>
    </FormatBadge>
  );
};

const download = async (format: string, asset: AssetRow, setProgress: (progress: number) => void) => {
  setProgress(0);
  const downloadFiles = asset.formatConverterMetadata?.downloadFiles ?? asset.actionsData?.downloadFiles;

  let fileToDownload = downloadFiles?.find((file) => file.orgName?.endsWith(format));

  if (!fileToDownload) {
    fileToDownload = downloadFiles?.find((file) => file.orgName?.endsWith(`${format}.zip`));
  }

  if (!fileToDownload) {
    return showErrorNotification({ message: 'File not found' });
  }

  const blob = await getBlob(fileToDownload.url, (e) => setProgress(Math.round((e.progress ?? 0) * 100)));
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileToDownload.exportName;
  a.click();
  a.remove();
};
