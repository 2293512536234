const defaultCliVersion = '7';

type VersionGroup = '7' | '6';

const externalLinks = {
  serviceStatus: () => 'https://status.rapidcompact.com/',
  onlineDocumentation: () => 'https://docs.rapidpipeline.com',
  changelog: (version?: VersionGroup) => {
    return {
      '7': 'https://docs.rapidpipeline.com/3d-processor-updates',
      '6': 'https://docs.rapidpipeline.com/rapidcompact-cli-updates',
    }[version ?? defaultCliVersion];
  },
  cliDoc: (version?: VersionGroup) => {
    return (
      {
        '7': 'https://docs.rapidpipeline.com/docs/componentDocs/3dProcessorCLI/cli-commands-guide-v1',
        '6': 'https://www.rapidcompact.com/doc/cli/index.html',
      }[version ?? defaultCliVersion] ?? ''
    );
  },
  cliSetup: (version?: VersionGroup) => {
    return {
      '7': 'https://docs.rapidpipeline.com/docs/componentDocs/3dProcessorCLI/cli-setup-guide',
      '6': 'https://docs.rapidpipeline.com/docs/componentDocs/legacy/legacy-cli-installation',
    }[version ?? defaultCliVersion];
  },
  supportHome: () => 'https://dgg3d.freshdesk.com/support/home',
  legacyFrontend: () => 'https://legacy.rapidcompact.com',
  assetGuidelines: () =>
    'https://docs.rapidcompact.com/docs/rapidpipeline-cloud-tutorials/getting-started#uploading-your-models',
  scaleWidget: () =>
    'http://rapidpipeline-public-drive.s3-website.eu-central-1.amazonaws.com/static-apps/bbox-size-vis',
  blenderPlugin: () => 'https://docs.rapidpipeline.com/docs/3dProcessor-Tutorials/blender-plugin-tutorials',
};

export default externalLinks;
