import { Menu } from '@mantine/core';
import Link from 'next/link';
import React from 'react';
import { IconCubePlus, IconCrown } from '@tabler/icons-react';
import { showErrorNotification } from '@/app/components/shared/notifications';

interface CreateEmbededItemProps {
  assetId: number;
  isAvailable: boolean;
}

const CreateEmbededItem: React.FC<CreateEmbededItemProps> = ({ assetId, isAvailable }) => {
  return (
    <Link
      onClick={(e) => {
        if (!isAvailable) {
          e.preventDefault();
          showErrorNotification({
            title: 'GLB File Required',
            message:
              'This output model is missing a .glb file, which is necessary for embedding. Please reprocess the source file with a preset that outputs .glb file.',
          });
        }
      }}
      href={`/embeded/rapid/${assetId}`}
    >
      <Menu.Item
        leftSection={<IconCubePlus style={{ width: 14, height: 14 }} />}
        rightSection={<IconCrown size={16} color='black' />}
      >
        Create embed
      </Menu.Item>
    </Link>
  );
};

export default CreateEmbededItem;
