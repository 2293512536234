import { Row } from '@tanstack/table-core';

export function expandAllChildren<T>(row: Row<T>, shouldExpand?: boolean) {
  const shouldBeExpanded = shouldExpand ?? !row.getIsExpanded();
  if (row.getCanExpand()) {
    if (row.getIsExpanded() !== shouldBeExpanded) {
      row.getToggleExpandedHandler()();
    }
    for (const childRow of row.subRows) {
      expandAllChildren(childRow, shouldBeExpanded);
    }
  }
}
