import { useMemo } from 'react';
import {
  getCoreRowModel,
  getExpandedRowModel,
  RowSelectionState,
  TableOptions,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import { ApiRawModel, RawModel, RawModelPbr, RpdInfo } from '@/types/rawModel';
import { useColumns } from '@/app/assets/components/table/hooks/useColumns';
import { AssetRow, AssetType } from '@/app/assets/components/table/hooks/types';
import { RapidModel } from '@/types/rapidModel';
import { OptimisticAssetsAction } from '@/app/assets/components/table/hooks/useOptimisticAssets';
import { PendingOutput } from '@/app/assets/actions/rawModelPendingOutputs';
import { getPBROutputsData } from '@/utils/assetUtils';
import { AdditionalDownloads } from '@/app/assets/actions/downloads';
import { hasImportError } from '@/app/assets/utils/getAssetErrors';
import { isCad, zippedFormats } from '@/utils/fileUtils';
import { formatsToConversion } from '../AddConvertedFormatsModal/AddConverterFormatsModal';

export const useAssetsTable = (
  assets: RawModel[],
  onRowSelectionChange?: (args: Updater<RowSelectionState>) => void,
  rowSelection?: RowSelectionState,
  isDisabled: boolean = false,
  onAssetDelete?: (action: OptimisticAssetsAction) => void,
  pendingOutputs?: PendingOutput[],
  useOnlyOutputs = false
) => {
  const { columns, columnsSpan } = useColumns(isDisabled, onAssetDelete, useOnlyOutputs);

  const data: AssetRow[] = useMemo(() => {
    const rows = assets.map((asset) => {
      const pendingOutputsForAsset = getPendingOutputs(pendingOutputs?.filter((p) => p.rawModelId === asset.id) ?? []);
      return rawModelToAssetRow(pendingOutputsForAsset, isDisabled)(asset);
    });

    if (useOnlyOutputs) {
      return rows.flatMap((row) => row.outputs.flatMap((p) => p.outputs));
    }

    return rows;
  }, [assets, isDisabled, pendingOutputs, useOnlyOutputs]);

  const options: TableOptions<AssetRow> = {
    getRowId,
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (originalRow) => originalRow.outputs,
    getRowCanExpand: (row) =>
      !!row.subRows?.length &&
      !hasImportError(row.original, false) &&
      (row.original.uploadStatus ? row.original.uploadStatus === 'complete' : true),
    enableSubRowSelection: false,
  };

  if (onRowSelectionChange && rowSelection) {
    options.onRowSelectionChange = onRowSelectionChange;
    options.state = {
      rowSelection,
    };
  }

  const table = useReactTable(options);

  return {
    table,
    columnsSpan,
  };
};

function getRapidOutputs(rapidModels: RapidModel[]): AssetRow[] {
  return rapidModels.map(rapidModelToAssetRow).flat();
}
function getPendingOutputs(pendingOutputs: PendingOutput[]): AssetRow[] {
  return (
    pendingOutputs.map((pending) => ({
      outputs: [],
      basicInfo: {
        id: pending.outputId,
        rowType: 'pendingOutput',
        name: pending.name,
        workFlowName: pending.workflowName,
        outputsCount: 0,
      },
      errors:
        pending.optimisationStatus === 'failed'
          ? [
              `Processing your file has failed. Please file a support ticket or email support@dgg3d.com. Job id: ${pending.workflowId}${pending.startedAt ? `, timestamp: ${new Date(pending.startedAt).toLocaleString()}` : ''}`,
            ]
          : undefined,
      disabled: true,
      workflowProgress: pending.progress,
      createdAt: pending.startedAt,
      workflowStatus: pending.optimisationStatus,
      isPBR: false,
    })) ?? []
  );
}

export function getPBROutputs(asset: RawModel, dcc: RapidModel, additionalDownloads?: AdditionalDownloads): AssetRow[] {
  let { formats, downloadFiles } = getPBROutputsData(asset, dcc.downloads as RawModelPbr);

  if (additionalDownloads) {
    formats = formats.concat(additionalDownloads.formats);
    downloadFiles = downloadFiles.concat(additionalDownloads.downloadFiles);
  }

  return [
    {
      basicInfo: {
        id: asset.id,
        name: asset.name,
        rowType: 'pbrOutput',
        outputsCount: 0,
      },
      formats,
      outputs: [],
      polygons: asset.rpdInfo.polygons,
      size: asset.size,
      createdAt: asset.created_at,
      actionsData: {
        downloadFiles: downloadFiles.map((f) => ({ exportName: f.name, url: f.url, orgName: f.name })),
      },
      formatConverterMetadata: {
        omitFormats: [asset.format],
      },
      optimisationStatus: asset.upload_status === 'complete' ? 'done' : 'initial',
      isPBR: asset.format === 'max',
    },
  ];
}

function getAdditionalDownloads(asset: RawModel): AssetRow[] {
  const { formats, downloadFiles } = asset.additionalDownloads ?? {
    formats: [],
    downloadFiles: [],
  };

  return [
    {
      basicInfo: {
        id: asset.id,
        name: asset.name,
        rowType: CONVERTER_ROW,
        outputsCount: 0,
      },
      formats: formats.filter((format) => formatsToConversion.includes(format)),
      outputs: [],
      polygons: asset.rpdInfo.polygons,
      size: asset.size,
      createdAt: asset.created_at,
      actionsData: {
        downloadFiles: downloadFiles
          .filter((f) => suffixesToMatchInDownloadFiles.some((suffix) => f.name.endsWith(suffix)))
          .map((f) => ({ exportName: f.name, orgName: f.name, url: f.url })),
      },
      formatConverterMetadata: {
        omitFormats: [asset.format],
      },
      optimisationStatus: asset.upload_status === 'complete' ? 'done' : 'initial',
      isPBR: asset.format === 'max',
    },
  ];
}

function getExtension(filename: string) {
  // Remove .zip if present
  if (filename.endsWith('.zip')) {
    filename = filename.slice(0, -4);
  }
  return filename.split('.').pop();
}

export function rapidModelToAssetRow(rapidModel: RapidModel): AssetRow {
  const formats = getRapidOutputFormats(rapidModel);

  return {
    basicInfo: {
      id: rapidModel.id,
      name: rapidModel.name,
      rowType: 'output',
      workFlowName: rapidModel.preset_name,
      outputsCount: 0,
      thumbnail: rapidModel.thumbnail?.first,
    },
    formats: formats,
    polygons: rapidModel.rpdInfo?.polygons,
    outputs: [],
    createdAt: rapidModel.created_at,
    size: rapidModel.meta?.size,
    actionsData: {
      downloadFiles: Object.entries(rapidModel.downloads?.all ?? {}).map(([key, url]) => {
        // A preset can have multiple outputs with the same extension
        // e.g. the key for the files would be "rapid.glb", "rapid1.glb", "rapid2.glb"
        // For the zip download we want to have all 3 glb files, so we add the number inside the key to the exportName
        const match = key.match(/\d+/);
        const exportName = match
          ? `${rapidModel.export_name}${match[0]}.${getExtension(key)}`
          : `${rapidModel.export_name}.${getExtension(key)}`;

        return {
          exportName,
          url,
          orgName: key,
        };
      }),
    },
    optimisationStatus: rapidModel.optimisationStatus,
    isPBR: false,
    disabled: rapidModel.disabled,
  };
}

export function getRowId(row: AssetRow) {
  const descriptor: AssetRowDescriptor = {
    type: row.basicInfo.rowType,
    id: row.basicInfo.id,
    isCad: !!row.formats?.some((format) => (typeof format === 'string' ? isCad(format) : isCad(format.format))),
    isMax: !!row.formats?.some((format) => (typeof format === 'string' ? format === 'max' : format.format === 'max')),
  };

  return JSON.stringify(descriptor);
}

export type AssetRowDescriptor = {
  id: AssetRow['basicInfo']['id'];
  type: AssetRow['basicInfo']['rowType'];
  isCad: boolean;
  isMax: boolean;
};

function getAllErrors(row: RawModel, outputs: AssetRow[]) {
  const outputErrors = outputs.reduce((errors, output) => {
    errors.push(...(output.errors ?? []));
    return errors;
  }, [] as string[]);

  return row.errors?.concat(outputErrors);
}

export function rawModelToAssetRow(pendingOutputsForAsset: AssetRow[], disabled: boolean) {
  return function (asset: RawModel): AssetRow {
    const isMax = asset.format === 'max';

    const outputs = getOutputsForRawAsset(isMax, asset, pendingOutputsForAsset);
    // Count the number of outputs in each category, so the row shows the correct amount of models
    const totalOutputs = outputs.reduce((acc, category) => acc + category?.outputs.length, 0);

    return {
      basicInfo: {
        id: asset.id,
        name: asset.name,
        thumbnail: asset.downloads?.['thumb.jpg'],
        rowType: 'raw',
        outputsCount: totalOutputs,
        disabled,
      },
      formats: [asset.format],
      size: asset.size,
      createdAt: asset.created_at,
      polygons: asset.rpdInfo.polygons ?? null,
      outputs,
      uploadStatus: asset.upload_status,
      warnings: asset.warnings,
      errors: getAllErrors(asset, outputs),
      isPBR: isMax,
      formatConverterMetadata: getFormatConverterMetadata(asset),
    };
  };
}

function getOutputsForRawAsset(
  isMax: boolean,
  asset: Omit<
    ApiRawModel,
    'uuid' | 'external' | 'import_status' | 'progress' | 'metadata' | 'error_message' | 'scale_factor' | 'has_errors'
  > & {
    rpdInfo: RpdInfo;
    rapidModels: RapidModel[];
    assetType: 'raw';
    scale_factor: number;
    warnings: string[];
    errors: string[];
    additionalDownloads?: AdditionalDownloads;
  },
  pendingOutputsForAsset: AssetRow[]
) {
  let rapids = asset.rapidModels;
  const outputs = [];

  if (isMax) {
    const dcc = asset.rapidModels.find((rapid) => rapid.preset_name === 'DCC Importer');
    if (!dcc) {
      throw new Error('DCC Importer not found');
    }
    const PBROutputs = getPBROutputs(asset, dcc, asset.additionalDownloads);
    outputs.push({
      basicInfo: {
        id: -asset.id,
        name: 'DCC Importer and Converted Files',
        rowType: 'pipeline' as AssetType,
        outputsCount: 1,
      },
      isPBR: true,
      outputs: PBROutputs,
    });
    rapids = asset.rapidModels.filter((rapid) => rapid.preset_name !== 'DCC Importer');
  }

  if (!isMax && asset.additionalDownloads && asset.additionalDownloads.formats.length) {
    const additionalDownloads = getAdditionalDownloads(asset);
    outputs.push({
      basicInfo: {
        id: -asset.id,
        name: 'Converted Files',
        rowType: 'pipeline' as AssetType,
        outputsCount: 1,
      },
      isPBR: false,
      outputs: additionalDownloads,
    });
  }

  const rapidOutputs = getRapidOutputs(rapids);
  if (rapids.length || pendingOutputsForAsset.length) {
    outputs.push({
      basicInfo: {
        id: asset.id,
        name: asset.downloads
          ? '3D Processor Output Models'
          : Object.keys(asset.downloads ?? {}).some((k) => k.endsWith('.max'))
            ? 'DCC Importer'
            : '3D Processor Output Models',
        rowType: 'pipeline' as AssetType,
        outputsCount: rapids.length,
      },
      outputs: rapidOutputs.concat(pendingOutputsForAsset),
      isPBR: false,
    });
  }
  return outputs;
}

function getRapidOutputFormats(rapidModel: RapidModel) {
  // Extracts the formats from the rapidModel downloads
  const formats: { filename: string; format: string }[] = Object.keys(rapidModel.downloads?.all ?? {}).reduce(
    (acc: { filename: string; format: string }[], filename) => {
      // Get the file extension
      const extension = getExtension(filename);
      if (extension) {
        // Add the filename and its format to the accumulator
        acc.push({ filename, format: extension });
      }
      return acc;
    },
    []
  );

  // Example:
  // rapidModel.downloads.all = {
  //   "model1.glb": "url1",
  //   "model2.obj": "url2"
  // }
  // Resulting formats array:
  // [
  //   { filename: "model1.glb", format: "glb" },
  //   { filename: "model2.obj", format: "obj" }
  // ]

  return formats;
}

const getFormatConverterMetadata = (asset: RawModel) => {
  let { formats, downloadFiles } = asset.additionalDownloads ?? { downloadFiles: [], formats: [] };
  if (asset.format === 'max') {
    const dcc = asset.rapidModels.find((rapid) => rapid.preset_name === 'DCC Importer');
    if (!dcc) {
      throw new Error('DCC Importer not found');
    }
    const PBRdata = getPBROutputsData(asset, dcc.downloads as RawModelPbr);
    formats = formats.concat(PBRdata.formats);
    downloadFiles = downloadFiles.concat(PBRdata.downloadFiles);
  }
  return {
    omitFormats: [asset.format],
    formats: formats.filter((format) => [...formatsToConversion, 'max'].includes(format)),
    downloadFiles: downloadFiles
      .filter((f) => [...suffixesToMatchInDownloadFiles, 'max', 'max.zip'].some((suffix) => f.name.endsWith(suffix)))
      .map((f) => ({ exportName: f.name, orgName: f.name, url: f.url })),
  };
};

export const CONVERTER_ROW = 'cadOutput';
export const DCC_IMPORTER_ROW = 'pbrOutput';

const suffixesToMatchInDownloadFiles = formatsToConversion.map((format) => {
  if (zippedFormats.includes(format)) {
    return `${format}.zip`;
  }
  return `.${format}`;
});
